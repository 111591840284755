import styled, {css} from 'styled-components';
import {Card, Typography} from 'antd';
import {Tooltip} from '../Tooltip';
import {StaticCardProps, CircleTypesProps} from './index';
import {Button, LinkButton} from '../Button';
import {defaultTheme} from '../theme/theme';
import {Media} from '../theme/breakpoints';
import {CodeAccessTypes} from '../helpers/codes';
import {fontPrimaryLight} from '../../ui-kit/theme/font';
const {Text} = Typography;

const smallTextStyle = css`
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: 12px;
  line-height: 16px;
`;

export const StyledCard = styled(Card).attrs({
  size: 'small',
})<StaticCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  padding-right: ${({tooltip}) => (!!tooltip ? '25px' : 'unset')};
  min-width: 150px;
  border-radius: 6px;
  .ant-card-body {
    padding-top: 8px;
  }
`;

export const CardLabel = styled(Text).attrs({
  type: 'secondary',
})`
  display: block;
  margin-bottom: 4px;
  ${smallTextStyle}
`;

export const CardValue = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 20px;
  line-height: 28px;
  display: block;
`;

export const CardTooltip = styled(Tooltip)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const DetailedCard = styled(Card)`
  margin-bottom: 33px;
  position: relative;
  border-radius: 8px;
  border-top: 1px solid ${({theme}) => theme.palette.background.lightGold};
  .ant-card-head {
    background-color: ${({theme}) => theme.palette.background.black};
  }
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${Media.down.l} {
    flex-wrap: wrap;
  }
`;

export const CardInfoColumn = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  min-width: 255px;
`;

export const CardInfoLabel = styled(Text).attrs({
  type: 'secondary',
})`
  margin-bottom: 8px;
  width: 100%;
  ${smallTextStyle}
`;

export const CardInfoValue = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: 14px;
  line-height: 22px;
  max-width: 400px;
`;

export const CardEditBtn = styled(LinkButton).attrs({
  type: 'link',
})`
  position: absolute;
  right: 30px;
  top: 17px;
  min-width: 73px;
`;

export const CardResyncButton = styled(Button)`
  position: absolute;
  right: 120px;
  top: 17px;
  min-width: 73px;
`;

export const AdditionalInfoBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 10px;
`;

export const AdditionalInfoRow = styled(Text).attrs({
  type: 'secondary',
})`
  font-size: 12px;
  line-height: 20px;
`;

export const AdditionalInfoRowTitle = styled(Text)`
  margin-right: 8px;
  color: ${({theme}) => theme.palette.common.darkGray};
  ${smallTextStyle}
`;

export const AdditionalInfoRowText = styled(Text)`
  margin-right: 8px;
  ${smallTextStyle}
`;

const getCircleColor = (variant?: CodeAccessTypes) => {
  const colors = {
    [CodeAccessTypes.sent]: defaultTheme.palette.common.darkGreen,
    [CodeAccessTypes.notsent]: defaultTheme.palette.common.brown,
  };
  return variant ? colors[variant] : defaultTheme.palette.common.orange;
};

export const StatusCircle = styled.div<CircleTypesProps>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 10px;
  border: 1px solid ${({variant}) => getCircleColor(variant)};
  background-color: ${({variant}) => getCircleColor(variant)};
`;

export const StatusText = styled(Text)`
  line-height: 32px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  color: ${({theme}) => theme.palette.common.white};
`;

export const StatusCircleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: ${({theme}) => theme.font.weight.regular};
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleSide = styled.div`
  & > h3 {
    color: #fff !important;
    margin-bottom: 0px;
  }
`;
export const Logo = styled.img`
  max-width: 200px;
  max-height: 40px;
  object-fit: contain;
  margin-right: 10px;
  margin-left: 10px;
`;

export const SelectSide = styled.div`
  & > .ant-select {
    width: 200px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const SelectionTitle = styled(Text)`
  ${fontPrimaryLight};
  font-size: 14px;
  line-height: 20px;
  color: #020e16;
`;
