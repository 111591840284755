import {Spin} from 'antd';
import styled from 'styled-components';

export const StyledSpin = styled(Spin)`
  color: ${({theme}) => (theme?.palette?.common ? theme.palette.common.primaryBlack : '#262626')};
  .ant-spin-dot {
    font-size: 24px;
  }
`;

export const PageLoaderContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot {
    font-size: 48px;
    color: #262626;
  }
`;

export const LoaderLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot {
    color: #262626;
  }
`;
