import React, {useState} from 'react';
import {Divider, Select} from 'antd';
import {
  OptionCheckBox,
  OptionRow,
  OptionRowContent,
  OptionRowOverlay,
  Options,
  StyledSelect,
  SelectButtonsWrapper,
  SelectOptionsButton,
  DD,
} from './styles';
import {ButtonTypes} from '../Button';
const {Option} = Select;

export type SelectOptionsT = {
  label: string;
  value: string;
};

export type SelectPropsT = {
  options: SelectOptionsT[];
  initial?: string[];
  placeholder?: string;
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  value?: string[];
};

export const SelectWithCheckbox: React.FC<SelectPropsT> = ({
  options,
  initial,
  placeholder,
  onChange,
  value,
  ...props
}) => {
  const [checked, setChecked] = useState<string[]>(initial || []);
  const [hidden, setHidden] = useState(false);
  const change = (v: any) => {
    setChecked(v);
    onChange?.(v);
  };
  const reset = () => setChecked([]);
  const unFocus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setTimeout(() => {
      setHidden(true);
    }, 200);
  };
  const onFocus = () => {
    setHidden(false);
  };
  const currentValue = value || checked;
  return (
    <>
      <StyledSelect
        {...props}
        mode="multiple"
        optionLabelProp="label"
        placeholder={placeholder ? placeholder : 'Select all that apply'}
        onChange={change}
        onClick={onFocus}
        onFocus={onFocus}
        value={currentValue}
        showArrow={true}
        dropdownRender={(menu) => (
          <DD $hidden={hidden}>
            <Options>{menu}</Options>
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <SelectButtonsWrapper>
              <SelectOptionsButton type={'primary'} variant={ButtonTypes.secondary} onClick={reset}>
                Reset
              </SelectOptionsButton>
              <SelectOptionsButton type={'primary'} onClick={unFocus}>
                Add
              </SelectOptionsButton>
            </SelectButtonsWrapper>
          </DD>
        )}>
        {options.map((el) => (
          <Option value={el.value} label={el.label} key={el.value}>
            <OptionRow>
              <OptionRowOverlay />
              <OptionRowContent>
                <OptionCheckBox checked={currentValue.includes(el.value)} />
                {el.label}
              </OptionRowContent>
            </OptionRow>
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};
