import {useRecoilState, useSetRecoilState} from 'recoil';
import {modals} from '../states/modals';
import {useEffect, useState} from 'react';

export const useLoggedModals = () => {
  const setModals = useSetRecoilState(modals);

  const checkLogged = (lastSessionDate?: string) => {
    if (!lastSessionDate) return setModals({helpExpand: false, subsequent: false, onboarding: true});
    setModals({helpExpand: true, subsequent: true, onboarding: false});
  };
  return checkLogged;
};

export type useHelpModalT = {
  expanded?: boolean;
};

export const useHelpModal = () => {
  const [_modals, setModals] = useRecoilState(modals);

  useEffect(() => {
    const click = () => {
      if (_modals.helpExpand) setModals((prev) => ({...prev, helpExpand: undefined}));
    };
    document.addEventListener('click', click);
    return () => document.removeEventListener('click', click);
  }, [_modals.helpExpand]);
  const restoreSubsequent = () => {
    setModals((prev) => ({...prev, subsequent: false}));
  };
  const restoreOnboarding = () => {
    setModals((prev) => ({...prev, onboarding: false}));
  };

  const triggerFlow = () => {
    setModals((prev) => {
      if (prev.triggerOnboarding === 'start') return {...prev, triggerOnboarding: 'continue'};
      if (prev.triggerOnboarding === 'end') return {...prev, triggerOnboarding: 'start'};
      if (prev.triggerOnboarding?.includes('continue'))
        return {...prev, triggerOnboarding: `continue${new Date().getTime()}`};
      return {...prev, triggerOnboarding: 'start'};
    });
  };
  return {
    helpModal: {expanded: _modals.helpExpand},
    subsequent: _modals.subsequent,
    triggerFlow,
    restoreSubsequent,
    uploadTriggered: _modals.triggerUpload,
    isOnboarding: _modals.onboarding,
    restoreOnboarding,
    triggerOnboarding: _modals.triggerOnboarding,
  };
};

export enum flowSteps {
  methods = 'methods',
  bulk = 'bulk',
  individual = 'individual',
  upload = 'upload',
  end = 'end',
}

export const useModalsFlow = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [step, setStep] = useState<string>();
  const [_modals, setModals] = useRecoilState(modals);

  const goMethods = () => setStep(flowSteps.methods);
  const choseBulk = () => setStep(flowSteps.bulk);
  const choseIndividual = () => setStep(flowSteps.individual);
  const goUpload = () => {
    setStep(flowSteps.upload);
    setModals((prev) => ({...prev, triggerUpload: true, triggerOnboarding: 'end'}));
  };
  const onFinish = () => {
    setModals((prev) => ({...prev, triggerOnboarding: 'end'}));
    setStep(flowSteps.end);
  };

  useEffect(() => {
    setShowOnboarding(_modals.onboarding);
  }, []);
  useEffect(() => {
    if (_modals.triggerOnboarding === 'start') {
      setModals((prev) => ({...prev, triggerUpload: false}));
      goMethods();
      setShowOnboarding(true);
    }
    if (_modals.triggerOnboarding?.includes('continue')) {
      setModals((prev) => ({...prev, triggerUpload: false}));
      setShowOnboarding(true);
    }
  }, [_modals.triggerOnboarding]);
  const onCloseFlowModals = (reset?: boolean) => {
    reset && setStep(flowSteps.methods);
    setShowOnboarding(false);
  };

  return {showOnboarding, step, choseBulk, choseIndividual, goMethods, goUpload, onFinish, onCloseFlowModals};
};
