import {CODES_PORTAL_URL, NOTIFICATIONS_PORTAL_URL} from '../constants/env';
import {getToken} from './auth';
import {permissionsList} from '../helpers/roles';

export const urlWithToken = (url: string, token: string, expires?: number) =>
  `${url}?token=${token}&expires=${expires || 604800}`;

export const getHeaderLinks = (permissions?: permissionsList) => {
  const token = getToken();
  const links = [
    ...(CODES_PORTAL_URL && permissions?.promoAccess
      ? [{key: 1, label: 'Promo Code Portal', href: urlWithToken(CODES_PORTAL_URL, token || '')}]
      : []),
    ...(NOTIFICATIONS_PORTAL_URL && permissions?.isSuperAdmin && permissions?.notificationsAccess
      ? [{key: 2, label: 'Notifications Portal', href: urlWithToken(NOTIFICATIONS_PORTAL_URL, token || '')}]
      : []),
  ];
  return links;
};
