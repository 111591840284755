import {AuthQueryList} from '../constants/api';
import {getQuery, postQuery} from './hooks';
import {User} from '../types/auth';

export type authorizeRes = User | null;

export const authorizeQuery = async () => await getQuery(AuthQueryList.authorize);

export const logoutQuery = async () => await postQuery(AuthQueryList.logout);

export const getAssociatesQuery = async () => await getQuery(AuthQueryList.getAssociates);
