import React from 'react';
import {Form} from '../../components/TicketGroup/Form/Form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useGetTicketGroup, useUpdateTickets} from '../../hooks/tickets';
import {toInitialTicketGroupState} from '../../helpers/tickets';
import {useGetAssociates, useViewer} from '../../hooks/auth';
import {getPermissions} from '../../helpers/roles';
import {openNotifError} from '../../components/Dashboard';

export const EditTicket = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {ticketGroup, loading} = useGetTicketGroup(id);
  const formData = useUpdateTickets(id, ticketGroup, openNotifError);
  const initial = toInitialTicketGroupState(ticketGroup);
  const {associatesOptions} = useGetAssociates();
  const user = useViewer();
  const {isAssociate, isDeptManager, isSuperAdmin} = getPermissions();
  const location = useLocation();
  const isFromDetailed = location?.search?.includes('fromDetailed=true');

  return (
    <Form
      mode={'edit'}
      formData={formData}
      initial={initial}
      isLoading={loading}
      navigate={navigate}
      ticketGroup={ticketGroup}
      associatesOptions={associatesOptions}
      user={user}
      isAssociate={isAssociate}
      isDeptManager={isDeptManager}
      isSuperAdmin={isSuperAdmin}
      isFromDetailed={isFromDetailed}
      groupId={id}
    />
  );
};
