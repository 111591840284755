import {css} from 'styled-components';

export const scrollStyles = css`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #fafafa;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cccfd0;
  }
`;
