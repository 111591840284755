import React from 'react';
import {useCreatePermission, useDeletePermission, useGetPermissions} from '../../hooks/settings';
import {PermissionsTable} from '../../components/Settings/ConfigurationsTable/Permissions';

export const PermissionsConfiguration = () => {
  const {tableData, loading, refetch} = useGetPermissions();
  const {onDelete} = useDeletePermission(refetch);
  const {create} = useCreatePermission(refetch);

  return <PermissionsTable data={tableData} loading={loading} onDelete={onDelete} onCreate={create} />;
};
