import React from 'react';
import {DepartmentTable} from '../../components/Settings/ConfigurationsTable/Department';
import {
  useCreateDepartments,
  useDeleteDepartments,
  useGetDepartments,
  useUpdateDepartments,
} from '../../hooks/settings';

export const DepartmentsConfiguration = () => {
  const {tableData, refetch, loading} = useGetDepartments();
  const {create} = useCreateDepartments(refetch);
  const {update} = useUpdateDepartments(refetch);
  const {onDelete} = useDeleteDepartments(refetch);

  return <DepartmentTable data={tableData} onCreate={create} onDelete={onDelete} onUpdate={update} loading={loading} />;
};
