import {Modal} from 'antd';
import React, {useEffect} from 'react';
import {flowSteps} from '../../../hooks/onboarding';
import {
  ButtonLabel,
  GoNextButton,
  MethodButton,
  MethodDescr,
  MethodTitle,
  MethodWrap,
  MethodsWrapper,
  ContentWrapper,
  Video,
  ButtonsPair,
  Delimetr,
  WideButton,
  DownloadButton,
  MoreInfoRow,
} from './styles';
import user from '../../../ui-kit/icons/user.svg';
import users from '../../../ui-kit/icons/users.svg';
import windows from '../../../ui-kit/icons/windows.svg';
import apple from '../../../ui-kit/icons/apple.svg';
import {ButtonTypes} from '../../../ui-kit/Button';
import {TGetCSV} from '../../../hooks/tickets';

type PropsT = {
  showOnboarding?: boolean;
  step?: string;
  choseBulk: () => void;
  choseIndividual: () => void;
  goMethods: () => void;
  goUpload: () => void;
  onFinish: () => void;
  csv: TGetCSV;
  onCloseFlowModals: (reset?: boolean) => void;
};

export const links = {
  video: 'https://www.loom.com/embed/99df30469d714d7592ed6980087d266b',
  more: 'https://www.notion.so/enchantlabs/Promo-Code-How-to-Format-Your-CSV-for-Bulk-Upload-c15b083560e14336ad4124a34860f833',
};

export const OnboardingModals: React.FC<PropsT> = ({
  showOnboarding,
  choseBulk,
  choseIndividual,
  step,
  goMethods,
  goUpload,
  onFinish,
  csv,
  onCloseFlowModals,
}) => {
  useEffect(() => {
    if (showOnboarding && !step) {
      const m1 = Modal.info({
        title: `Welcome To Your Order Details Dashboard`,
        content: (
          <ContentWrapper>
            <p>Here you will assign codes to your contacts, and see the code redemption status for each contact.</p>
            <b>Step 1:</b>
            <p>
              To start, add your contact names and emails (via bulk upload or by entering them individually), along with
              the quantity of codes they should receive.
            </p>
            <b>Step 2:</b>
            <p>
              Once you have assigned codes to your contacts, you can distribute them via email (in bulk or
              individually).
            </p>
            <p>
              You can toggle between different orders by selecting the order dropdown near the top-left of the page.
            </p>
            <GoNextButton
              type="primary"
              onClick={() => {
                goMethods();
                m1.destroy();
              }}
              variant={ButtonTypes.primary}>
              Next
            </GoNextButton>
          </ContentWrapper>
        ),
        autoFocusButton: null,
        keyboard: true,
        maskClosable: true,
        className: 'no-buttons title-lg',
        icon: '',
        width: 480,
      });
    }

    if (showOnboarding && step === flowSteps.methods) {
      const m2 = Modal.info({
        title: `How Would You Like To Upload Your Contacts?`,
        content: (
          <MethodsWrapper>
            <MethodButton
              onClick={() => {
                choseBulk();
                m2.destroy();
              }}>
              <ButtonLabel>Suggested</ButtonLabel>
              <img src={users} />
              <MethodWrap>
                <MethodTitle>Bulk Upload Contacts</MethodTitle>
                <MethodDescr>
                  Upload many contacts from a spreadsheet file with their names, emails, and quantity of codes.
                </MethodDescr>
              </MethodWrap>
            </MethodButton>
            <MethodButton
              onClick={() => {
                choseIndividual();
                m2.destroy();
              }}>
              <img src={user} />
              <MethodWrap>
                <MethodTitle>Individually Enter Contacts</MethodTitle>
                <MethodDescr>
                  Type contact names and emails into the portal, then assign them one or more codes.
                </MethodDescr>
              </MethodWrap>
            </MethodButton>
          </MethodsWrapper>
        ),
        autoFocusButton: null,
        keyboard: true,
        maskClosable: true,
        className: 'no-buttons title-lg',
        icon: '',
        width: 460,
        afterClose: onCloseFlowModals,
      });
    }

    if (showOnboarding && step === flowSteps.bulk) {
      const m3 = Modal.info({
        title: `Download Template File`,
        content: (
          <ContentWrapper>
            <p>
              Use our simple template to format your contact data before uploading it. Please choose an option for your
              device:
            </p>
            <Video src={links.video} allowFullScreen={true} />
            <ButtonsPair>
              <DownloadButton
                type="primary"
                download={csv.name}
                href={csv.url}
                onClick={() => {
                  goUpload();
                  m3.destroy();
                }}>
                <img src={windows} />
                Download For Windows
              </DownloadButton>
              <DownloadButton
                type="primary"
                download={csv.name}
                href={csv.url}
                onClick={() => {
                  goUpload();
                  m3.destroy();
                }}>
                <img src={apple} />
                Download For Mac
              </DownloadButton>
            </ButtonsPair>
            <Delimetr />
            <WideButton
              onClick={() => {
                goUpload();
                m3.destroy();
              }}>
              I already have the template
            </WideButton>
            <MoreInfoRow>
              <b>Need more info? </b>{' '}
              <a href={links.more} target="_blank" rel="noreferrer">
                Visit our help resource
              </a>
            </MoreInfoRow>
          </ContentWrapper>
        ),
        autoFocusButton: null,
        keyboard: true,
        maskClosable: true,
        className: 'no-buttons title-lg',
        icon: '',
        width: 520,
        afterClose: () => onCloseFlowModals(true),
      });
    }

    if (showOnboarding && step === flowSteps.individual) {
      Modal.info({
        title: `How To Navigate Your Order Details Page`,
        content: (
          <ContentWrapper $no_mg>
            <p>
              To start, add contacts by typing their name, email, and assigning a number of codes. Once you have added
              contacts, send them an email with their codes and a purchase link by clicking “Bulk Send Codes.”
            </p>
            <p>
              As codes are sent and redeemed by each contact, you can see their status here. You can also resend codes
              to contacts as needed, add more contacts, and update contact details.
            </p>
            <p>
              You can also choose to upload contacts in bulk by clicking “Upload Contacts”, or change portal groups by
              clicking the dropdown near the top-right of the page.
            </p>
            <p>If you have any questions or issues, please reach out to us - we’re happy to help!</p>
          </ContentWrapper>
        ),
        autoFocusButton: null,
        keyboard: true,
        maskClosable: true,
        className: 'no-buttons bordered-title',
        closable: true,
        afterClose: onFinish,
        icon: '',
        width: 460,
      });
    }
  }, [showOnboarding, step]);

  return null;
};
