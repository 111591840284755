import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {route} from './constants/routes';
import {RedirectToLogin} from './containers/RedirectToLogin';
import {Dashboard} from './containers/Dashboard';
import {PrivateRoute} from './containers/PrivateRoute';
import {EditTicket} from './containers/TicketGroup/Edit';
import {CreateTicket} from './containers/TicketGroup/Create';
import {ViewTicket} from './containers/TicketGroup/View';
import {SettingsPage} from './containers/Settings';

const PublicRoutes = [
  <Route key="redirect-to-login-page" path={route.redirectToLogin.path} element={<RedirectToLogin />} />,
];

const PrivateRoutes = [
  <Route
    key="dashboard"
    path={route.dashboard.path}
    element={
      <PrivateRoute path={route.dashboard.path}>
        <Dashboard />
      </PrivateRoute>
    }
  />,
  <Route
    key="create-ticket-group"
    path={route.createTicketGroup.path}
    element={
      <PrivateRoute path={route.createTicketGroup.path}>
        <CreateTicket />
      </PrivateRoute>
    }
  />,
  <Route
    key="edit-ticket-group"
    path={route.editTicketGroup.path}
    element={
      <PrivateRoute path={route.editTicketGroup.path}>
        <EditTicket />
      </PrivateRoute>
    }
  />,
  <Route
    key="view-ticket-group"
    path={route.viewTicketGroup.path}
    element={
      <PrivateRoute path={route.viewTicketGroup.path}>
        <ViewTicket />
      </PrivateRoute>
    }
  />,
  <Route
    key="settings"
    path={route.settings.path}
    element={
      <PrivateRoute path={route.settings.path}>
        <SettingsPage />
      </PrivateRoute>
    }
  />,
];

const RoutesSwitch: React.FC = () => {
  return (
    <Routes>
      {PrivateRoutes}
      {PublicRoutes}
    </Routes>
  );
};

export default RoutesSwitch;
