import {LoadingOutlined} from '@ant-design/icons';
import React from 'react';
import {LoaderLine, PageLoaderContainer, StyledSpin} from './styles';
import {Spin} from 'antd';

export const Loader = ({...props}) => <StyledSpin indicator={<LoadingOutlined />} {...props} />;

export const PageLoader = () => (
  <PageLoaderContainer>
    <Spin indicator={<LoadingOutlined />} />
  </PageLoaderContainer>
);

export const CenteredLoader = () => (
  <LoaderLine>
    <Loader />
  </LoaderLine>
);
