import styled from 'styled-components';
import {Typography} from 'antd';
import {Button} from '../Button';
const {Title, Text} = Typography;

export const UploadModalWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: block;
  padding: 24px;
`;

export const UploadModalTitle = styled(Title).attrs({
  level: 3,
})`
  font-size: 24px;
`;

export const UploadModalDescr = styled(Text)`
  font-size: 13px;
  margin-top: 24px;
  font-weight: 600;
  display: block;
  padding-right: 4px;
`;

export const UploadModalActions = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  gap: 4px;
`;

export const UploadModalCancelBtn = styled(Button)`
  width: 200px;
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #ffffff;
  color: #495258;
`;

export const UploadModalApplyBtn = styled(Button)`
  width: 200px;
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #937e6b;
  color: #ffffff;
`;

export const StyledModalFileLink = styled.a`
  color: #937e6b;
`;
