import React from 'react';
import {CardProps, Select, Typography} from 'antd';
import {
  StyledCard,
  CardValue,
  CardLabel,
  CardTooltip,
  CardEditBtn,
  CardInfoLabel,
  CardInfoColumn,
  CardInfoValue,
  CardInfoWrapper,
  DetailedCard,
  AdditionalInfoBlock,
  AdditionalInfoRow,
  AdditionalInfoRowTitle,
  AdditionalInfoRowText,
  StatusCircle,
  StatusCircleWrapper,
  StatusText,
  CardTitleWrapper,
  TitleSide,
  SelectSide,
  CardResyncButton,
  RightSide,
  SelectionTitle,
  Logo,
} from './styles';
import {ButtonTypes} from '../Button';
import {palette} from '../theme/palette';
import {CenteredLoader} from '../Loader';
import {CodeAccessTypes, CodeGroupTitleMap} from '../helpers/codes';
import {SelectOptionsT} from '../Select';
const {Title, Text} = Typography;

export type StaticCardProps = CardProps & {
  tooltip?: string;
  label?: string;
  value?: string;
  footNoteValue?: string;
};

export type CircleTypesProps = {
  variant?: CodeAccessTypes;
  title?: string;
  showStatus?: CodeAccessTypes;
  options?: SelectOptionsT[];
  onChange?: (value?: any) => void;
  defaultOption?: SelectOptionsT;
  logo?: string;
};

export const StaticCard: React.FC<StaticCardProps> = ({tooltip, label, value, footNoteValue, ...props}) => {
  return (
    <StyledCard {...props} size={'small'} tooltip={tooltip}>
      <CardLabel>{label}</CardLabel>
      <CardValue>{value}</CardValue>
      {footNoteValue && <AdditionalInfoRow>{footNoteValue}</AdditionalInfoRow>}
      {tooltip && <CardTooltip title={<Text>{tooltip}</Text>} color={palette.common.white} />}
    </StyledCard>
  );
};

export type BigCardProps = {
  lines?: {label: string; value?: string | React.ReactNode}[];
  buttonText?: string;
  onButtonClick?: () => void;
  buttonLink?: string;
  additionalInfo?: string[];
  cardTitle?: string;
  status?: CodeAccessTypes;
  loading?: boolean;
  titleOptions?: SelectOptionsT[];
  titleOnChange?: (value?: any) => void;
  defaultOption?: SelectOptionsT;
  onResync?: () => void;
  logo?: string;
};

const CardTitle: React.FC<CircleTypesProps> = ({
  title = '',
  showStatus,
  onChange,
  options,
  defaultOption,
  logo,
  ...props
}) => {
  return (
    <CardTitleWrapper>
      <TitleSide>
        <Title level={3}>
          {logo && <Logo src={logo} />}
          {title}
        </Title>
        {showStatus ? (
          <StatusCircleWrapper>
            <StatusCircle {...props} variant={showStatus} />
            <StatusText>{CodeGroupTitleMap?.[showStatus]}</StatusText>
          </StatusCircleWrapper>
        ) : null}
      </TitleSide>
      <RightSide>
        {!!options?.length && options?.length > 1 && <SelectionTitle>Switch between different groups:</SelectionTitle>}
        {!!options?.length && (
          <SelectSide>
            <Select options={options} defaultValue={defaultOption} value={defaultOption} onChange={onChange} />
          </SelectSide>
        )}
      </RightSide>
    </CardTitleWrapper>
  );
};

export const BigCard: React.FC<BigCardProps> = ({
  buttonText,
  onButtonClick,
  buttonLink,
  lines,
  additionalInfo,
  cardTitle,
  status,
  loading,
  titleOnChange,
  titleOptions,
  defaultOption,
  onResync,
  logo,
  ...props
}) => {
  return (
    <DetailedCard
      {...props}
      title={
        <CardTitle
          title={cardTitle}
          showStatus={status}
          options={titleOptions}
          onChange={titleOnChange}
          defaultOption={defaultOption}
          logo={logo}
        />
      }>
      {onResync && (
        <CardResyncButton variant={ButtonTypes.secondary} onClick={onResync}>
          Sync New Tickets
        </CardResyncButton>
      )}
      {buttonText && (
        <CardEditBtn variant={ButtonTypes.secondary} onClick={onButtonClick} to={buttonLink || '#'}>
          {buttonText}
        </CardEditBtn>
      )}

      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <CardInfoWrapper>
            {lines?.map((el) => (
              <CardInfoColumn key={el.label}>
                <CardInfoLabel>{el.label}</CardInfoLabel>
                <CardInfoValue>{el.value}</CardInfoValue>
              </CardInfoColumn>
            ))}
          </CardInfoWrapper>

          <AdditionalInfoBlock>
            <AdditionalInfoRowTitle>{additionalInfo ? additionalInfo[0] : null}</AdditionalInfoRowTitle>
            <AdditionalInfoRowText>{additionalInfo ? additionalInfo[1] : null}</AdditionalInfoRowText>
          </AdditionalInfoBlock>
        </>
      )}
    </DetailedCard>
  );
};
