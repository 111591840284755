import React from 'react';
import {TicketActionTypes, TicketTypeRow} from '../types/tickets';
import {ButtonTypes} from '../ui-kit/Button';
import {CodeAccessTypes} from '../ui-kit/helpers/codes';

export type TableColumnT = {
  title: string | React.ReactNode;
  dataIndex: string;
  key: string;
  render?: (a: any) => React.ReactNode;
  fixed?: 'left' | 'right';
};

export enum DashboardTableFields {
  id = 'id',
  clientName = 'clientName',
  ticketType = 'ticketType',
  department = 'department',
  contactPerson = 'contactPerson',
  createdOn = 'createdOn',
  totalTickets = 'totalTickets',
  ticketsUsed = 'ticketsUsed',
  status = 'status',
  actions = 'actions',
}

export const DashboardTableColumnsTitles = {
  id: 'ID',
  clientName: 'Client Name',
  ticketType: 'Ticket Type',
  department: 'Department',
  contactPerson: 'Contact Person',
  createdOn: 'Created on',
  totalTickets: 'Total Tickets',
  ticketsUsed: 'Tickets Sent',
  status: 'Status',
  actions: '',
};

export const DashboardCodeMap = {
  [CodeAccessTypes.sent]: ButtonTypes.secondary,
  [CodeAccessTypes.notsent]: ButtonTypes.primary,
};

export const StatusTitleMap = {
  [CodeAccessTypes.sent]: 'Shared',
  [CodeAccessTypes.notsent]: 'Pending',
};

export enum ManagementTableFields {
  fullName = 'fullName',
  email = 'email',
  role = 'role',
  department = 'department',
  access = 'access',
  actions = 'actions',
}

export const ManagementTableColumnsTitles = {
  fullName: 'Full Name',
  email: 'Email Address',
  role: 'Role',
  department: 'Department',
  access: 'Portal Access',
  actions: 'Actions',
};

export enum RoleConfigsTableFields {
  name = 'name',
  view = 'view',
  manageGroup = 'manageGroup',
  manageDistr = 'manageDistr',
  actions = 'actions',
}

export const RoleConfigsTableColumnsTitles = {
  role: 'Roles',
  view: 'View-only',
  manageGroup: 'Manage Group',
  manageDistr: 'Manage Distribution',
  action: 'Action',
};

export enum ConfigsTableFields {
  types = 'types',
  actions = 'actions',
}

export const ConfigsTableColumnsTitles = {
  types: 'Ticket Types',
  action: 'Actions',
};

export const ViewTicketMap = {
  [TicketActionTypes.sent]: ButtonTypes.tertiary,
  [TicketActionTypes.applied]: ButtonTypes.quaternary,
};

//------- DEPARTMENT ---------
export enum DepartmentTableFields {
  name = 'name',
  actions = 'actions',
}

export const DepartmentTableColumnsTitles = {
  types: 'Types',
  action: 'Actions',
};

//------- PERMISSIONS ---------
export enum PermissionTableFields {
  name = 'name',
  description = 'description',
  actions = 'actions',
}

export const PermissionTableColumnsTitles = {
  name: 'Name',
  description: 'Description',
  action: 'Actions',
};

export type GroupedTicketTypesT = Record<string, TicketTypeRow[]>;
export const groupTicketTypesForTable = (types?: TicketTypeRow[]): GroupedTicketTypesT => {
  if (!types) return {};
  const templateNames: GroupedTicketTypesT = {};
  types.forEach((el) => {
    if (el.eventTemplateName === 'Fees' || !el.eventTemplateName) return;
    templateNames[el.eventTemplateName] = [...(templateNames[el.eventTemplateName] || []), el];
  });
  return templateNames;
};
