import React, {useEffect, useState} from 'react';
import {
  TicketsTable,
  MenuAction,
  ModalDescr,
  LoaderWrapper,
  ClearButton,
  EnterButton,
  ResultLabel,
  SearchBtn,
  SearchInput,
  SearchResultWrapper,
  SearchWrapper,
} from './styles';
import {TableWrapper} from '../../ui-kit/Table';
import {DashboardTableDataT} from '../../types/dashboard';
import {
  DashboardCodeMap,
  DashboardTableColumnsTitles,
  DashboardTableFields,
  StatusTitleMap,
  TableColumnT,
} from '../../helpers/table';
import {ButtonMore, InfoButton} from '../../ui-kit/Button';
import {Dropdown, Menu, Modal} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {Loader} from '../../ui-kit/Loader';
import {CloseOutlined, SearchOutlined, WarningOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {route} from '../../constants/routes';
import {format} from 'date-fns';

enum popupLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
}

type DashboardTableProps = {
  goEdit: (id: string) => void;
  onDelete: (id: string) => void;
  data: DashboardTableDataT[];
  loading?: boolean;
  canAddEdit?: boolean;
  searchTickets: (v: string) => void;
  metricsLoading?: boolean;
};

type SearchProps = {
  value: string;
  setValue: (v: string) => void;
  searchTickets: (v: string) => void;
  showResult: (v: boolean) => void;
  disabled?: boolean;
};

type SearchResultProps = SearchProps & {
  resQty?: number;
};

export const DashboardTable: React.FC<DashboardTableProps> = ({
  data,
  goEdit,
  onDelete,
  loading,
  canAddEdit,
  searchTickets,
  metricsLoading,
}) => {
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [delPopupLvl, setDelPopupLvl] = useState<popupLvl>(popupLvl.closed);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchRes, setIsSearchRes] = useState<boolean>(false);
  const setDelPopup = (lvl: popupLvl) => setDelPopupLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (id?: string) => id && goEdit(id);
  useEffect(() => {
    const group = data?.find((el) => el?.id === focusedId);
    if (delPopupLvl === popupLvl.confirm) {
      Modal.confirm({
        title: `Do you want to delete the group ticketing order for the ${group?.clientName?.name}?`,
        content: (
          <ModalDescr>
            Once you have confirmed, the group ticketing order will be removed from the Portal, and you won’t be able to
            make any further edits.
          </ModalDescr>
        ),
        okText: 'Yes, proceed to delete',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          focusedId && onDelete(focusedId), setDelPopup(popupLvl.success);
        },
        onCancel: () => setDelPopup(popupLvl.closed),
      });
    }
    if (delPopupLvl === popupLvl.success) {
      Modal.success({
        title: `The code group has been deleted!`,
        content: <ModalDescr>You will not see the code group on the Portal now.</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          console.log('delete', focusedId);
          setDelPopup(popupLvl.closed);
        },
      });
    }
  }, [delPopupLvl]);

  const columns: TableColumnT[] = [
    {
      key: DashboardTableFields.id,
      title: DashboardTableColumnsTitles.id,
      dataIndex: DashboardTableFields.id,
      render: (id: string) => <Link to={route.viewTicketGroup.get({id})}>{id}</Link>,
    },
    {
      key: DashboardTableFields.clientName,
      title: DashboardTableColumnsTitles.clientName,
      dataIndex: DashboardTableFields.clientName,
      render: ({id, name}) => <Link to={route.viewTicketGroup.get({id})}>{name}</Link>,
    },
    {
      key: DashboardTableFields.ticketType,
      title: DashboardTableColumnsTitles.ticketType,
      dataIndex: DashboardTableFields.ticketType,
    },
    {
      key: DashboardTableFields.department,
      title: DashboardTableColumnsTitles.department,
      dataIndex: DashboardTableFields.department,
    },
    {
      key: DashboardTableFields.contactPerson,
      title: DashboardTableColumnsTitles.contactPerson,
      dataIndex: DashboardTableFields.contactPerson,
    },
    {
      key: DashboardTableFields.createdOn,
      title: DashboardTableColumnsTitles.createdOn,
      dataIndex: DashboardTableFields.createdOn,
      render: (value: string) => {
        return <>{value && format(new Date(value), 'd LLL, Y')}</>;
      },
    },
    {
      key: DashboardTableFields.totalTickets,
      title: DashboardTableColumnsTitles.totalTickets,
      dataIndex: DashboardTableFields.totalTickets,
    },
    {
      key: DashboardTableFields.ticketsUsed,
      title: DashboardTableColumnsTitles.ticketsUsed,
      dataIndex: DashboardTableFields.ticketsUsed,
    },
    {
      key: DashboardTableFields.status,
      title: DashboardTableColumnsTitles.status,
      dataIndex: DashboardTableFields.status,
      render: (status: DashboardTableDataT['status']) => {
        return status?.groupType === 'external' ? (
          <InfoButton variant={status.type && DashboardCodeMap?.[status.type]}>
            {status.type && StatusTitleMap?.[status.type]}
          </InfoButton>
        ) : null;
      },
    },
    {
      key: DashboardTableFields.actions,
      dataIndex: DashboardTableFields.actions,
      title: (
        <Search
          searchTickets={searchTickets}
          showResult={setIsSearchRes}
          value={searchValue}
          setValue={setSearchValue}
          disabled={metricsLoading}
        />
      ),
      render: (props: DashboardTableDataT['actions']) => (
        <MenuMore
          id={props?.id}
          setDelPopup={setDelPopup}
          setFocusId={setFocusId}
          setEditing={setEditing}
          goEdit={goEdit}
          canAddEdit={canAddEdit}
        />
      ),
    },
  ];

  const locale = {
    emptyText: <ResultLabel>There are no results found for your search.</ResultLabel>,
  };

  return (
    <>
      {isSearchRes && searchValue && (
        <SearchResult
          resQty={data?.length}
          showResult={setIsSearchRes}
          searchTickets={searchTickets}
          value={searchValue}
          setValue={setSearchValue}
        />
      )}
      <Modal />
      <TableWrapper>
        <TicketsTable
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={isSearchRes && !data?.length ? locale : undefined}
        />
        {loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </TableWrapper>
    </>
  );
};

type MenuMoreProps = {
  id?: string;
  setDelPopup?: (lvl: popupLvl) => void;
  setFocusId?: (id?: string) => void;
  setEditing?: (id?: string) => void;
  goEdit?: (id: string) => void;
  canAddEdit?: boolean;
};

export const MenuMore: React.FC<MenuMoreProps> = ({id, setDelPopup, setEditing, setFocusId, goEdit, canAddEdit}) => {
  const onEdit = () => {
    setFocusId?.(id);
    setEditing?.(id);
    setDelPopup?.(popupLvl.closed);
    id && goEdit?.(id);
  };
  const onDelete = () => {
    setFocusId?.(id);
    setDelPopup?.(popupLvl.confirm);
  };

  const ddActions: ItemType[] = canAddEdit
    ? [
        {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
        {
          key: 2,
          label: (
            <MenuAction $danger onClick={onDelete}>
              Delete
            </MenuAction>
          ),
        },
      ]
    : [];

  if (!canAddEdit) return null;
  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};

export const Search: React.FC<SearchProps> = ({value, setValue, searchTickets, showResult, disabled}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
    setTimeout(() => document?.getElementById('search-input-dashboard')?.focus(), 100);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const handleOnSearch = () => {
    searchTickets(value);
    setOpen(false);
    showResult(true);
  };

  const searchInput = (
    <SearchWrapper>
      <SearchInput
        prefix={<SearchOutlined />}
        placeholder="Search by client name or contact person..."
        onChange={handleInputChange}
        onPressEnter={handleOnSearch}
        value={value}
        id="search-input-dashboard"
      />
      <EnterButton onClick={handleOnSearch} disabled={value === ''}>
        Search
      </EnterButton>
    </SearchWrapper>
  );

  return (
    <SearchBtn disabled={disabled}>
      <Dropdown
        overlay={searchInput}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}>
        {open ? <CloseOutlined /> : <SearchOutlined />}
      </Dropdown>
    </SearchBtn>
  );
};

const SearchResult: React.FC<SearchResultProps> = ({resQty, showResult, value, setValue, searchTickets}) => {
  const onClearClick = () => {
    setValue('');
    searchTickets('');
    showResult(false);
  };

  return (
    <SearchResultWrapper>
      <ResultLabel>{`${resQty} Results for "${value}"`}</ResultLabel>
      <ClearButton onClick={onClearClick}>Clear Search</ClearButton>
    </SearchResultWrapper>
  );
};
