import styled from 'styled-components';
import {Checkbox} from 'antd';

export const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox-inner {
    background-color: ${({theme}) => theme.palette.common.white};
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({theme}) => theme.palette.common.brown};
    border-color: ${({theme}) => theme.palette.common.brown};

    &:after {
      display: block;
    }
  }
  .ant-checkbox-input {
    display: none;
    :hover + .ant-checkbox-inner {
      border-color: ${({theme}) => theme.palette.common.brown};
    }
    :focus + .ant-checkbox-inner {
      box-shadow: none;
      border-color: ${({theme}) => theme.palette.common.brown};
    }
  }

  .ant-checkbox-checked:after {
    border-color: ${({theme}) => theme.palette.common.brown};
  }
`;

// table
// border-collapse: collapse
// margin: 0px -10px 0 -10px;
//     width: calc(100% + 20px);

//tbody
//border: 20px solid transparent;

//hfdSzQ .ant-table-tbody tr td:first-of-type:before {
// border-left: 1px solid #F0F0F0;
// border-top-left-radius: 2px;
// border-bottom-left-radius: 2px;
// content: '';
// height: 65px;
// position: absolute;
// top: 0;
// left: 0;
