import React from 'react';
import {ViewTicketGroup} from '../../components/TicketGroup/View';
import {useParams} from 'react-router-dom';
import {
  useGetListTicketGroup,
  useGetTicketGroup,
  useTicketsActions,
  useSendTicket,
  useMultipleSend,
  useMultipleUpdate,
  useSetTicketState,
  useAddGuests,
  useMultipleSendbyEmails,
  useSendNotSentEmails,
  useReSync,
  useUpdateGuest,
  useUploadGuestEmails,
  useResetGroup,
} from '../../hooks/tickets';
import {InternalTicketFooterContainer, TicketFooterContainer} from '../Layout/Footer';
import {getPermissions} from '../../helpers/roles';
import {openNotifError} from '../../components/Dashboard';
import {Onboarding} from './Onboarding';
import {useHelpModal} from '../../hooks/onboarding';

export const ViewTicket = () => {
  const {id} = useParams();
  const {
    ticketGroup,
    loading: ticketLoading,
    refetch: groupRefetch,
    metrics,
    groupedTicketTypes,
  } = useGetTicketGroup(id);
  const {
    tableData,
    loading: listLoading,
    refetch,
    usedTickets,
    emailsMap,
    searchTickets,
  } = useGetListTicketGroup(id, metrics);
  const handleRefetch = () => {
    groupRefetch();
    refetch();
  };
  const {onDelete, onUpdate} = useTicketsActions(id, handleRefetch, openNotifError);
  const {onSend} = useSendTicket(id, handleRefetch);
  const addGuestData = useAddGuests(metrics, usedTickets, id, handleRefetch);
  const {onUpdateGuest} = useUpdateGuest(id, refetch, openNotifError);
  const {reSync} = useReSync(ticketGroup, handleRefetch, openNotifError);
  const ticketState = useSetTicketState();
  const {updateAll} = useMultipleUpdate({groupId: id});
  const {sendAll} = useMultipleSend({groupId: id, onSuccess: ticketState.reset});
  const {sendMultiple, loadingSend} = useMultipleSendbyEmails(id, handleRefetch, openNotifError);
  const {send: sendBulk} = useSendNotSentEmails([id || ''], openNotifError);
  const onBulk = async () => {
    await sendBulk();
    await updateAll(ticketState.state);
    await sendAll(ticketState.state);
    document.location.reload();
  };
  const {isAssociate} = getPermissions();
  const canAddEdit = !isAssociate;
  const modals = useHelpModal();
  const {upload: uploadEmails} = useUploadGuestEmails(id ? [id] : [], handleRefetch);
  const handleReset = useResetGroup(ticketGroup, handleRefetch);
  return (
    <>
      <Onboarding id={id} />
      <ViewTicketGroup
        ticketGroup={ticketGroup}
        ticketLoading={ticketLoading}
        tableData={tableData}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onSendTicket={onSend}
        listLoading={listLoading}
        ticketState={ticketState}
        groupRefetch={groupRefetch}
        addGuestData={addGuestData}
        sendMultiple={sendMultiple}
        loadingSend={loadingSend}
        canAddEdit={canAddEdit}
        fullRefetch={handleRefetch}
        onBulk={onBulk}
        viewMetrics={metrics}
        usedTickets={usedTickets}
        emailsMap={emailsMap}
        onUpdateGuest={onUpdateGuest}
        reSync={reSync}
        searchTickets={searchTickets}
        onUploadEmails={uploadEmails}
        modals={modals}
        groupedTicketTypes={groupedTicketTypes}
        handleReset={handleReset}
      />
      {ticketGroup?.id ? (
        ticketGroup?.groupType === 'external' ? (
          <TicketFooterContainer onSuccess={handleRefetch} />
        ) : (
          <InternalTicketFooterContainer onSuccess={handleRefetch} onBulk={onBulk} />
        )
      ) : (
        <></>
      )}
    </>
  );
};
