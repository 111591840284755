import React from 'react';
import {EmptyHeaderContainer, HeaderLogoBig} from './styles';
import Logo from './assets/ENC_Logo.png';

export const EmptyHeader = () => {
  return (
    <EmptyHeaderContainer>
      <HeaderLogoBig src={Logo} />
    </EmptyHeaderContainer>
  );
};
