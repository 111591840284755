import React from 'react';
import {TooltipProps} from 'antd';
import {StyledTooltip, TooltipIcon} from './styles';

export type TooltipPropsT = {
  icon?: React.ReactNode;
};

export const Tooltip: React.FC<TooltipProps & TooltipPropsT> = ({icon, ...props}) => (
  <StyledTooltip {...props}>{icon ? icon : <TooltipIcon />}</StyledTooltip>
);
