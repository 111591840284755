import styled from 'styled-components';
import {CustomCheckbox} from '../Checkbox';
import {Select} from 'antd';
import {Button} from '../Button';

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({theme}) => theme.palette.common.brown};
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  .ant-select-item-option {
    background-color: transparent;
  }

  & .ant-select-selection-overflow {
    cursor: pointer;
  }
  cursor: pointer;
`;

export const Options = styled.div`
  & .ant-select-item-option-selected {
    background-color: ${({theme}) => theme.palette.common.white};
  }
  & .ant-select-item-option-state {
    display: none;
  }
`;

export const OptionCheckBox = styled(CustomCheckbox)`
  margin-right: 10px;
`;

export const OptionRow = styled.div`
  position: relative;
`;

export const OptionRowOverlay = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

export const OptionRowContent = styled.div``;

export const SelectButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectOptionsButton = styled(Button)`
  margin-right: 16px;
  margin-bottom: 4px;
`;

export const DD = styled.div<{$hidden?: boolean}>`
  display: ${({$hidden}) => ($hidden ? `none` : `block`)};
`;
