import React, {useEffect, useState} from 'react';
import {PageWrapper, TitleLine} from '../styles';
import {
  CodeForm,
  CodeFormItem,
  FormButton,
  GroupTitle,
  StyledSelect,
  TooltipWrapper,
  WithHint,
  SuccessLine,
  SuccessText,
  ErrorLine,
  ErrorText,
  LinkError,
  PreviewBlock,
  ImagePreview,
} from './styles';
import {LinkBack} from '../../../ui-kit/Button/LinkBack';
import {FormMode, TicketGroupCreateFields} from '../../../types/tickets';
import Input, {TextArea} from '../../../ui-kit/Input';
import {Tooltip} from '../../../ui-kit/Tooltip';
import {Checkbox, Form as FormAntd, Modal, message} from 'antd';
import {SelectOptionT} from '../../../types/helpers';
import {FormTips} from './tips';
import {useCheckValidation, useCreateTicketsT} from '../../../hooks/tickets';
import {DeleteOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {palette} from '../../../ui-kit/theme/palette';
import {route} from '../../../constants/routes';
import {SelectWithCheckbox} from '../../../ui-kit/Select';
import {ticketGroupBody, TicketGroupT} from '../../../queries/types';
import {Loader} from '../../../ui-kit/Loader';
import {NavigateFunction} from 'react-router-dom';
import {CodeAccessTypes} from '../../../ui-kit/helpers/codes';
import {CheckValidationLabel} from './CheckValidation';
import {User} from '../../../types/auth';
import {parseStringArray, validateEmailsArray} from '../../../helpers/common';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {ImageUpload} from '../../../ui-kit/Input/FileUpload';
const {Text} = Typography;

export type TicketFormProps = {
  mode: FormMode;
  formData: useCreateTicketsT;
  initial?: Partial<ticketGroupBody>;
  isLoading?: boolean;
  navigate: NavigateFunction;
  ticketGroup?: TicketGroupT;
  associatesOptions?: SelectOptionT[];
  user?: User | null;
  isAssociate?: boolean;
  isDeptManager?: boolean;
  isSuperAdmin?: boolean;
  isFromDetailed?: boolean;
  groupId?: string;
};
const formTitles = {create: 'Create New Ticket Group', edit: 'Update Client Profile'};
const formButtons = {create: 'Create Tickets Group', edit: 'Update Tickets Group'};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: 'Invalid email. Please enter a valid email.',
  },
};

type ItemLabelProps = {
  label: string;
  title: string | React.ReactNode;
};

const ItemLabel: React.FC<ItemLabelProps> = ({label, title}) => {
  return (
    <>
      <span>{label}</span>
      <TooltipWrapper>
        <Tooltip title={<Text>{title}</Text>} color={palette.common.white} />
      </TooltipWrapper>
    </>
  );
};

export enum popupLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
  confirmLeave = 'confirmLeave',
}

export const Form: React.FC<TicketFormProps> = ({
  mode,
  formData,
  initial,
  isLoading,
  navigate,
  ticketGroup,
  associatesOptions,
  user,
  isAssociate,
  isDeptManager,
  isSuperAdmin,
  groupId,
  isFromDetailed,
}) => {
  const {onCreate, success, error, id, resetResult, options} = formData;
  const [form] = FormAntd.useForm();

  const [ticketUrl, setTicketUrl] = useState('');
  const [imageFileList, setImageFileList] = useState([]);
  const [imagePreview, setImagePreview] = useState<string | undefined>();
  const [brand, setBrand] = useState('');
  const isCreate = mode === 'create';
  const title = formTitles[mode];
  const button = formButtons[mode];
  const [loading, setLoading] = useState(false);
  const [delPopupLvl, setDelPopupLvl] = useState<popupLvl>(popupLvl.closed);
  const [values, setValues] = useState<any>({});
  const {onCheck, validStatus, isLoading: checkLoading, setStatus} = useCheckValidation(ticketUrl, undefined, brand);
  const [showValidation, setShowValidation] = useState(false);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [department, setDepartment] = useState<string>();
  const [associates, setAssociates] = useState<SelectOptionT[]>([]);
  const [dateOverride, setDateOverride] = useState(false);
  // const validStatus: checkValidationStatus = ticketUrl ? (isValid ? 'checked' : 'invalid') : 'none';

  useEffect(() => {
    if (isCreate) {
      setDepartment(user?.departments && user?.departments[0]);
    } else {
      setDepartment(initial?.department);
    }
  }, [initial?.department, user?.departments]);

  useEffect(() => {
    if (!department || !associatesOptions) return;
    const associatesByDepartment = associatesOptions?.filter((el) => el.departments?.includes(department));
    setAssociates(associatesByDepartment);
  }, [department, !!associatesOptions?.length]);
  const toggleOverride = (e: CheckboxChangeEvent) => setDateOverride(e.target.checked);
  const onChangeTT = (value: string[]) => {
    !showValidation && setIsFieldChanged(true);
    form.setFieldsValue([{[TicketGroupCreateFields.ticketType]: value}]);
  };
  const onChangeAssign = (value: string[]) => {
    !showValidation && setIsFieldChanged(true);
    form.setFieldsValue([{[TicketGroupCreateFields.managedByUsers]: value}]);
  };
  const onBrandChange = (value: string) => {
    setBrand(value);
    form.setFieldsValue([{[TicketGroupCreateFields.brand]: value}]);
  };
  const goDetailView = (id: string) => navigate(route.viewTicketGroup.get({id}));
  const disabled =
    ticketGroup?.outboundStatus === CodeAccessTypes.sent &&
    ticketGroup?.groupType === 'external' &&
    !isSuperAdmin &&
    !isAssociate;
  const disabledUrl = ticketGroup?.outboundStatus === CodeAccessTypes.sent && ticketGroup?.groupType === 'external';
  const disabledGroupType = disabledUrl;
  const disabledClientName = disabled && !isDeptManager;
  const disableDepartment = isAssociate ? true : disabled;
  const disableAssign = isAssociate
    ? true
    : ticketGroup?.outboundStatus === CodeAccessTypes.sent &&
      ticketGroup?.groupType === 'external' &&
      !isDeptManager &&
      !isSuperAdmin;
  const initialDepartment =
    (isCreate && isAssociate) || (isCreate && isDeptManager)
      ? user?.departments && user?.departments[0]
      : initial?.department;
  const initialAssign = isAssociate ? [user?.email] : initial?.managedByUsers;

  const optionsGroup: SelectOptionT[] = [
    {label: 'Internal', value: 'internal'},
    {label: 'External', value: 'external'},
  ];
  const selectsChange = () => {
    setLoading(false);
    resetResult?.();
    !showValidation && setIsFieldChanged(true);
  };

  const selectChangeDepartment = (value: string) => {
    setDepartment(value);
    setLoading(false);
    resetResult?.();
    !showValidation && setIsFieldChanged(true);
  };

  const handleChangeForm = () => {
    setLoading(false);
    resetResult?.();
    !showValidation && setIsFieldChanged(true);
  };
  const onFinish = async (values: any) => {
    const emails = parseStringArray(values[TicketGroupCreateFields.clientEmail]);
    const validEmail = validateEmailsArray(emails);
    if (!validEmail) return message.error({content: 'Please enter valid email'});

    setValues({
      ...values,
      [TicketGroupCreateFields.ticketureOrderId]: ticketUrl,
      [TicketGroupCreateFields.clientEmail]: JSON.stringify(emails),
    });
    setDelPopupLvl(popupLvl.confirm);
  };
  const setModalSuccess = () => {
    setDelPopupLvl(popupLvl.success);
  };

  const imageUploadProps = {
    onRemove: (file: any) => {
      // eslint-disable-next-line
      //@ts-ignore
      const index = imageFileList.indexOf(file);
      const newFileList = imageFileList.slice();
      newFileList.splice(index, 1);
      setImagePreview('');
      setImageFileList(newFileList);
    },
    // eslint-disable-next-line
    //@ts-ignore
    beforeUpload: (file) => {
      // eslint-disable-next-line
      //@ts-ignore
      setImageFileList([file]);
      setImagePreview(URL.createObjectURL(file));
      return false;
    },
    fileList: imageFileList,
    accept: 'image/*',
    name: TicketGroupCreateFields.clientLogo,
  };

  useEffect(() => {
    if (delPopupLvl === popupLvl.confirm) {
      Modal.confirm({
        title: mode === 'create' ? `Are you ready to create this group?` : `Are you ready to update this group?`,
        content: <div>Please make sure all the details are correct.</div>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => {
          onCreate?.({...values, clientLogo: values?.clientLogo?.file}, setModalSuccess);
          setLoading(true);
        },
        onCancel: () => {
          setDelPopupLvl(popupLvl.closed);
        },
      });
    }
    if (delPopupLvl === popupLvl.success) {
      Modal.success({
        title: mode === 'create' ? `This group was created successfully!` : `This group was updated successfully!`,
        content: <div>You can now view this group and send to the client.</div>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setDelPopupLvl(popupLvl.closed);
        },
      });
    }
  }, [delPopupLvl]);
  const createDisabled = isCreate || (!isCreate && !disabled) ? validStatus !== 'checked' : false;
  useEffect(() => {
    if (success?.status === 'completed' && id) goDetailView(id);
  }, [success, id]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (initial?.ticketureOrderId) {
      setTicketUrl(initial?.ticketureOrderId);
    }
  }, [initial?.ticketureOrderId]);

  useEffect(() => {
    if (initial?.metadata?.overrideDateField) {
      setDateOverride(!!initial?.metadata?.overrideDateField);
    }
  }, [initial?.metadata?.overrideDateField]);

  useEffect(() => {
    if (isFieldChanged) {
      setIsDisabled(false);
    }
  }, [isFieldChanged]);
  const onGoBackClick = (e?: any) => {
    e?.preventDefault();
    if (isFromDetailed && groupId) return navigate(route.viewTicketGroup.get({id: groupId}));
    navigate(route.dashboard.path);
  };

  const imageSRC = imagePreview || initial?.clientLogo;
  const clearImage = () => {
    setImageFileList([]);
    setImagePreview('');
  };
  const canRemove = imageSRC !== initial?.clientLogo;

  if (isLoading)
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    );
  const backText = isFromDetailed ? 'Back to Group View' : 'Back to Dashboard';
  return (
    <PageWrapper>
      <TitleLine>
        <LinkBack onClick={onGoBackClick}>{backText}</LinkBack>
      </TitleLine>
      <CodeForm
        layout={'vertical'}
        form={form}
        onChange={handleChangeForm}
        onFinish={onFinish}
        validateMessages={validateMessages}>
        <GroupTitle>{title}</GroupTitle>
        <CodeFormItem
          label={'Brand Name*'}
          name={[TicketGroupCreateFields.brand]}
          rules={[{required: true, message: 'Select brand'}]}
          initialValue={initial?.brand}>
          <StyledSelect
            placeholder={'Select brand'}
            options={options.brand}
            // eslint-disable-next-line
            //@ts-ignore
            onChange={onBrandChange}
          />
        </CodeFormItem>
        <CodeFormItem
          label={<ItemLabel label="Ticket ID*" title={FormTips.ticketUrl} />}
          rules={[{required: false, message: 'Ticket ID is required'}]}
          name={[TicketGroupCreateFields.ticketureOrderId]}
          initialValue={initial?.ticketureOrderId}>
          <Input
            value={ticketUrl}
            placeholder="Enter Ticket ID or URL"
            onChange={({target}) => {
              setTicketUrl(target.value);
              setStatus('none');
              setShowValidation(true);
            }}
            disabled={disabledUrl}
            suffix={
              (mode === 'create' || (showValidation && mode === 'edit')) && (
                <CheckValidationLabel
                  canCheck={!!ticketUrl?.length && !!brand}
                  onClick={onCheck}
                  isLoading={checkLoading}
                  status={validStatus}
                />
              )
            }
          />
          <LinkError>{validStatus === 'invalid' && 'Invalid Link, please try again'}</LinkError>
        </CodeFormItem>

        <CodeFormItem
          label={<ItemLabel label="Client Name*" title={FormTips.clientName} />}
          rules={[{required: true, message: 'Client name is required'}]}
          name={[TicketGroupCreateFields.clientName]}
          initialValue={initial?.clientName}>
          <Input placeholder="Enter contact name" disabled={disabledClientName} />
        </CodeFormItem>

        <CodeFormItem
          label={<ItemLabel label="Client Logo*" title={FormTips.clientLogo} />}
          name={[TicketGroupCreateFields.clientLogo]}
          initialValue={initial?.clientLogo}>
          <ImageUpload {...imageUploadProps} placeholder="Upload File" />
        </CodeFormItem>
        {imageSRC && (
          <PreviewBlock>
            <ImagePreview src={String(imageSRC)} />
            <p>
              This is how ths logo is will look on emails.
              <br /> Please ensure this works for dark mode as well.
            </p>
            <span>{canRemove && <DeleteOutlined onClick={clearImage} />}</span>
          </PreviewBlock>
        )}

        <CodeFormItem
          label={'Contact Person*'}
          name={[TicketGroupCreateFields.contactPerson]}
          initialValue={initial?.contactPerson}>
          <Input placeholder="Enter contact name" />
        </CodeFormItem>

        <CodeFormItem
          label={<ItemLabel label="Client Email*" title={FormTips.clientEmail} />}
          name={[TicketGroupCreateFields.clientEmail]}
          rules={[{required: true, message: 'Client Email is required'}]}
          initialValue={initial?.clientEmailList}>
          <Input placeholder="Enter email" />
        </CodeFormItem>

        <CodeFormItem
          label={'Group Type*'}
          name={[TicketGroupCreateFields.groupType]}
          rules={[{required: true, message: 'Group Type is required'}]}
          initialValue={initial?.groupType}>
          <StyledSelect
            placeholder={'Select group type'}
            onChange={selectsChange}
            options={optionsGroup}
            disabled={disabledGroupType}
          />
        </CodeFormItem>
        <CodeFormItem
          label={'Department*'}
          name={[TicketGroupCreateFields.department]}
          rules={[{required: true, message: 'Department is required'}]}
          initialValue={isCreate ? user?.departments && user?.departments[0] : initialDepartment}>
          <StyledSelect
            placeholder={'Enter department'}
            // eslint-disable-next-line
            //@ts-ignore
            onChange={selectChangeDepartment}
            options={options.departments}
            disabled={disableDepartment}
          />
        </CodeFormItem>

        <CodeFormItem
          label={'Assign to Person'}
          name={[TicketGroupCreateFields.managedByUsers]}
          initialValue={initialAssign}>
          <SelectWithCheckbox
            onChange={onChangeAssign}
            // eslint-disable-next-line
            //@ts-ignore
            options={associates}
            initial={initialAssign}
            disabled={disableAssign}
          />
        </CodeFormItem>

        <CodeFormItem
          label={<ItemLabel label="Ticket Type*" title={FormTips.ticketType} />}
          name={[TicketGroupCreateFields.ticketType]}
          rules={[{required: isCreate, message: 'Ticket Type is required'}]}
          initialValue={initial?.ticketType?.split(',')}>
          <SelectWithCheckbox
            onChange={onChangeTT}
            options={formData.options.tickets}
            initial={initial?.ticketType ? initial?.ticketType?.split(',') : undefined}
            disabled={disabled}
          />
        </CodeFormItem>

        <WithHint>
          <CodeFormItem
            label={<ItemLabel label="Description*" title={FormTips.description} />}
            name={[TicketGroupCreateFields.description]}
            initialValue={initial?.description}
            rules={[{required: isCreate, message: 'Description is required'}]}>
            <TextArea rows={4} placeholder={'Describe details'} disabled={disabled} />
          </CodeFormItem>
        </WithHint>
        <WithHint>
          <CodeFormItem
            label={<ItemLabel label="Override Date/Time" title={FormTips.overrideDateTime} />}
            name={dateOverride ? [TicketGroupCreateFields.overrideDateField] : undefined}
            initialValue={initial?.metadata?.overrideDateField}
            rules={[{required: isCreate, message: 'Description is required'}]}>
            {dateOverride ? (
              <Input />
            ) : (
              <Checkbox onChange={toggleOverride} value={dateOverride}>
                Not Overridden
              </Checkbox>
            )}
          </CodeFormItem>
        </WithHint>
        <ErrorLine>{error && <ErrorText>{error}</ErrorText>}</ErrorLine>
        {success?.status === 'completed' ? (
          <Success mode={mode} />
        ) : (
          <FormButton htmlType="submit" type={'primary'} disabled={loading || (createDisabled && isDisabled)}>
            {button}
          </FormButton>
        )}
      </CodeForm>
    </PageWrapper>
  );
};

export const Success: React.FC<{mode: FormMode}> = ({mode}) => {
  return (
    <SuccessLine>
      {mode === 'create' ? (
        <SuccessText>Tickets Group successfully created</SuccessText>
      ) : (
        <SuccessText>Tickets Group successfully updated</SuccessText>
      )}
    </SuccessLine>
  );
};
