import React from 'react';
import {TableProps} from 'antd';
import {StyledPrimaryTable, StyledTableWrapper} from './styles';
import {WithChildren} from '../AppLayout';

export const PrimaryTable: React.FC<TableProps<any>> = ({...props}) => <StyledPrimaryTable {...props} />;

export const TableWrapper: React.FC<WithChildren> = ({children, ...props}) => (
  <StyledTableWrapper {...props}>{children}</StyledTableWrapper>
);
