import {authorizeQuery, authorizeRes, logoutQuery, getAssociatesQuery} from '../queries/auth';
import Cookies from 'js-cookie';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {useEffect, useState} from 'react';
import {session as sessionState, userState} from '../states/session';
import {LOGIN_EXTERNAL_LINK} from '../constants/env';
import {useSearchParams} from 'react-router-dom';
import {Portals, searchKeys} from '../types/helpers';
import {urlWithParams} from '../helpers/Route';
import {UserT} from '../queries/types/user';
import {PortalsList} from '../types/auth';
import {filterAssociatesByDepartment} from '../helpers/roles';

type TokenType = string | null | undefined;

export const getToken = (): TokenType => {
  return Cookies.get('id_token');
};

export const setToken = (token: TokenType, expires?: number): TokenType | void => {
  if (!token) {
    return Cookies.remove('id_token');
  }
  return Cookies.set('id_token', token, {expires: expires || 365});
};

export function useFetchSession() {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramToken = searchParams.get(searchKeys.token);
  const paramExpires = searchParams.get(searchKeys.expires);

  const [loading, setLoading] = useState(true);
  const [success, SetSuccess] = useState(false);
  const checkAuth = useCheckAuthorize();
  const setSession = useSetRecoilState(sessionState);
  const [mounted, setMounted] = useState(false);
  const token = getToken();

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
    const setAuthorize = async () => {
      if (!token && !paramToken) return;

      //check token from params
      if (paramToken) {
        setToken(paramToken, Number(paramExpires));
        const res2 = await checkAuth();
        const haveAccess = !!res2?.portals?.find((el) => el?.toLowerCase() === PortalsList.ticket.toLowerCase());
        if (res2 && haveAccess) {
          setSession({sessionToken: paramToken || '', user: res2});
          SetSuccess(true);
        }
        searchParams.delete(searchKeys.token);
        searchParams.delete(searchKeys.expires);
        setSearchParams(searchParams);
        setLoading(false);
        return;
      }

      //check exist token
      if (token) {
        const res = await checkAuth();
        if (res) {
          setSession({sessionToken: token, user: res});
          SetSuccess(true);
          setLoading(false);
          return;
        }
      }

      setLoading(false);
    };
    setAuthorize();
  }, []);

  if (!mounted) return {loading: true, success};
  if (!token && !paramToken) {
    setToken(null);
    setSession(null);
    return {loading: false, success};
  }
  return {loading, success};
}

export const useCheckAuthorize = () => async () => {
  try {
    const res = await authorizeQuery();
    return res?.body as authorizeRes;
  } catch (e) {
    return null;
  }
};

export const useViewer = () => {
  const user = useRecoilState(userState);

  return user[0];
};

export const useLogOut = () => async () => {
  const res = await logoutQuery();
  if (res?.ok) {
    setToken(null);
    LOGIN_EXTERNAL_LINK && window.location.replace(urlWithParams(LOGIN_EXTERNAL_LINK, true, Portals.groups));
  }
};

export const useGetAssociates = () => {
  const [associates, setAssociates] = useState<UserT[]>([]);
  const viewer = useViewer();

  const fetch = async () => {
    try {
      const res = await getAssociatesQuery();
      setAssociates(res?.body);
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return {
    associates,
    associatesOptions: filterAssociatesByDepartment(['Group Ticket'], viewer?.departments, associates)?.map((el) => ({
      departments: el.departments,
      label: `${el?.firstName} ${el?.email}`,
      value: el?.email,
    })),
  };
};
