import React from 'react';
import {FooterText, FooterWrapper, ReshareAccessButton, FooterCenterText, Actions} from './styles';
import {TITLE} from '../constants';

export type FooterPropsT = {
  text?: string;
  showReshare?: boolean;
  buttonText?: string;
  onReshare?: () => void;
  showAdditional?: boolean;
  actionText?: string;
  handleAction?: () => void;
};

export const Footer = (props: FooterPropsT) => {
  const {text, showReshare, buttonText, onReshare, showAdditional, actionText, handleAction} = props;
  const baseText = `© ${TITLE} ${new Date().getFullYear()}.`;

  return (
    <FooterWrapper {...props} zIndex={2}>
      {showReshare ? (
        <>
          <FooterText>{text || baseText}</FooterText>
          <Actions>
            {showAdditional && <ReshareAccessButton onClick={handleAction}>{actionText}</ReshareAccessButton>}
            <ReshareAccessButton onClick={onReshare}>{buttonText}</ReshareAccessButton>
          </Actions>
        </>
      ) : (
        <FooterCenterText>{text || baseText}</FooterCenterText>
      )}
    </FooterWrapper>
  );
};
