import React, {useEffect, useState, useRef, useCallback} from 'react';
import {PageWrapper, TitleLine} from '../styles';
import {LinkBack} from '../../../ui-kit/Button/LinkBack';
import {route} from '../../../constants/routes';
import {GroupDetailTable, ModalLvl} from './Table';
import {DashboardCardT} from '../../../types/dashboard';
import {getTableTicketsFilterOptions, getTicketsCountText, usedMetricsT} from '../../../helpers/tickets';
import {EmailsMapT, GroupDetailTableDataT, TicketStatusTypes, TicketTypeRow} from '../../../types/tickets';
import {BigCard} from '../../../ui-kit/StaticCard';
import {TableCards} from './TableCards';
import {ValueItem, ValueItemSmall, ValueNameItem, ValueWrapper} from './styles';
import {StyledTableContentWrapper} from '../../../ui-kit/Table/styles';
import {TicketGroupT} from '../../../queries/types';
import {Modal} from 'antd';
import {
  onUpdateGuestT,
  sendMultipleT,
  useAddGuestStateT,
  useSetTicketStateT,
  useTicketsActionsT,
} from '../../../hooks/tickets';
import {BulkModal, PDFModal} from '../../../containers/TicketGroup/ShareModal';
import {getFilterTableData} from '../../../helpers/helpers';
import {UploadModalEmails} from './UploadModal';
import {GroupedTicketTypesT} from '../../../helpers/table';
import {ResetModal} from './ResetModal';

type ViewTicketGroupProps = {
  ticketGroup?: TicketGroupT;
  ticketLoading?: boolean;
  tableData?: GroupDetailTableDataT[];
  onUpload?: (values: any) => void;
  onUpdate?: useTicketsActionsT['onUpdate'];
  onDelete?: useTicketsActionsT['onDelete'];
  onSendTicket: (id?: string) => Promise<boolean>;
  listLoading?: boolean;
  ticketState: useSetTicketStateT;
  groupRefetch: () => void;
  addGuestData: useAddGuestStateT;
  sendMultiple?: sendMultipleT;
  loadingSend: boolean;
  canAddEdit?: boolean;
  fullRefetch?: () => void;
  onBulk?: () => void;
  usedTickets?: usedMetricsT;
  viewMetrics?: TicketTypeRow[];
  groupedTicketTypes: GroupedTicketTypesT;
  emailsMap?: EmailsMapT;
  // onUpdateTickets: onUpdateTicketsT;
  onUpdateGuest: onUpdateGuestT;
  reSync?: () => Promise<void>;
  searchTickets: (v: string) => void;
  modals: {
    helpModal: {
      expanded: boolean | undefined;
    };
    subsequent: boolean | undefined;
    triggerFlow: () => void;
    restoreSubsequent: () => void;
    uploadTriggered: boolean | undefined;
    isOnboarding: boolean;
    restoreOnboarding: () => void;
    triggerOnboarding?: string;
  };
  onUploadEmails: (file: any, onSuccess?: () => void) => Promise<any>;
  handleReset?: () => Promise<any>;
};

export const ViewTicketGroup: React.FC<ViewTicketGroupProps> = ({
  ticketGroup,
  ticketLoading,
  tableData,
  onDelete,
  onUpdate,
  onSendTicket,
  listLoading,
  viewMetrics,
  ticketState,
  groupRefetch,
  addGuestData,
  sendMultiple,
  loadingSend,
  onBulk,
  usedTickets,
  emailsMap,
  onUpdateGuest,
  reSync,
  searchTickets,
  onUploadEmails,
  modals,
  groupedTicketTypes,
  handleReset,
}) => {
  const {triggerFlow, subsequent, isOnboarding, uploadTriggered} = modals;
  const [filter, setFilter] = useState<TicketStatusTypes>(TicketStatusTypes.all);
  const handleSetFilter = (value: TicketStatusTypes) => setFilter(value);
  const [emailsModalLvl, setEmailsModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [modalLvlBulk, setModalLvlBulk] = useState<ModalLvl>(ModalLvl.closed);
  const options = getTableTicketsFilterOptions();
  const [pageWidth, setPageWidth] = useState<number | undefined>();
  const pageEl = useRef<HTMLHeadingElement>(null);
  const [filterTableData, setFilterTableData] = useState<GroupDetailTableDataT[] | undefined>();
  const [pdfModal, setPdfModal] = useState<boolean>(false);
  const handlePdfModalClose = useCallback(() => setPdfModal(false), []);
  const handlePdfModalOpen = useCallback(() => setPdfModal(true), []);
  const {openResetModal} = ResetModal(handleReset);
  const cards: DashboardCardT[] = [
    {
      label: 'Tickets Uploaded',
      value: String(ticketGroup?.ticketsUploaded ?? ''),
    },
    {
      label: 'Tickets Sent',
      value: String(ticketGroup?.ticketsSent ?? ''),
      tooltip: 'The number of tickets that clients send guests',
    },
    // {
    //   label: 'Tickets Redeemed',
    //   value: String(ticketGroup?.ticketsUsed ?? ''),
    //   tooltip: 'The total numbers of codes that guests check out ticket orders with codes applied',
    // },
  ];
  const cardLineValues = {
    contact: [ticketGroup?.contactPerson, ticketGroup?.clientEmailList?.join('\n') || ticketGroup?.clientEmail],
    ticketTypes: ticketGroup?.ticketType?.split(','),
  };

  const cardLines = [
    {
      label: 'Contact Details',
      value: (
        <ValueWrapper>
          <ValueNameItem>{cardLineValues.contact[0]}</ValueNameItem>
          <ValueItem>{cardLineValues.contact[1]}</ValueItem>
        </ValueWrapper>
      ),
    },
    {
      label: 'Ticket Types',
      value: (
        <ValueWrapper>
          {cardLineValues.ticketTypes?.map((el) => (
            <ValueItem key={el}>{el}</ValueItem>
          ))}
        </ValueWrapper>
      ),
    },
    {
      label: 'Details',
      value: (
        <ValueWrapper>
          <ValueItem>Order Number: {ticketGroup?.ticketureOrderNumber}</ValueItem>
          <ValueItem>
            All tickets are applicable {ticketGroup?.details?.sellerVenue} in {ticketGroup?.details?.sellerName} only
          </ValueItem>
          {/*<ValueItem>Event date: {'December 22, 2022'}</ValueItem>*/}
          {/*{viewMetrics?.map((el) =>*/}
          {/*  el?.ticketTypeUploaded ? (*/}
          {/*    <ValueItem>*/}
          {/*      {el?.ticketDisplayName} arrival window: {'6:00 PM'}*/}
          {/*    </ValueItem>*/}
          {/*  ) : null,*/}
          {/*)}*/}
          <ValueItem>{getTicketsCountText(viewMetrics)}</ValueItem>
          <div>-</div>
          <ValueItem>{ticketGroup?.description}</ValueItem>
          <ValueItemSmall>{ticketGroup?.metadata?.overrideDateField}</ValueItemSmall>
        </ValueWrapper>
      ),
    },
  ];

  const onUploadSuccess = () => {
    setEmailsModalLvl(ModalLvl.closed);
  };

  const onAddEmailsCodes = async (value: any[]) => await onUploadEmails?.(value);

  const onCancelUploadEmails = () => setEmailsModalLvl(ModalLvl.closed);

  const openUploadEmails = () => {
    //always show template modal
    if (subsequent || true) {
      triggerFlow();
      // restoreSubsequent();
      return;
    }
    //setModalLvl(ModalLvl.success);
  };

  useEffect(() => {
    if (emailsModalLvl === ModalLvl.success) {
      Modal.success({
        onOk: () => {
          setEmailsModalLvl(ModalLvl.closed);
        },
        modalRender: () => (
          <UploadModalEmails
            onCancel={onCancelUploadEmails}
            onAdd={onAddEmailsCodes}
            uploadTriggered={uploadTriggered}
            onUploadSuccess={onUploadSuccess}
            isOnboarding={isOnboarding}
          />
        ),
        width: 460,
        maskClosable: true,
        afterClose: () => {
          setEmailsModalLvl(ModalLvl.closed);
        },
        style: {pointerEvents: 'all'},
      });
    }
    if (emailsModalLvl === ModalLvl.closed) Modal.destroyAll();
  }, [emailsModalLvl]);

  useEffect(() => {
    if (uploadTriggered) setEmailsModalLvl(ModalLvl.success);
  }, [uploadTriggered]);

  const openBulkUpload = () => setModalLvlBulk(ModalLvl.confirm);

  useEffect(() => {
    if (!pageEl) return;
    setPageWidth(pageEl?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    const filterTableData = getFilterTableData(filter, tableData);
    setFilterTableData(filterTableData);
  }, [tableData, filter]);

  return (
    <>
      <PageWrapper>
        <TitleLine>
          <LinkBack to={route.dashboard.path}>Back to Dashboard</LinkBack>
        </TitleLine>
        <BigCard
          cardTitle={ticketGroup?.clientName}
          lines={cardLines}
          buttonText={'Edit'}
          status={ticketGroup?.outboundStatus}
          buttonLink={route.editTicketGroup.get({id: String(ticketGroup?.id)}) + '?fromDetailed=true'}
          additionalInfo={[
            'Created by:',
            `${ticketGroup?.user?.firstName} ${ticketGroup?.user?.lastName}, ${ticketGroup?.department} (${ticketGroup?.groupType})`,
          ]}
          loading={!ticketGroup?.id && ticketLoading}
          onResync={reSync}
          logo={ticketGroup?.clientLogoUrl}
        />
        {/*<EventTableWrapper>/!*<EventCards cards={eventCards} />*!/</EventTableWrapper>*/}
        <StyledTableContentWrapper ref={pageEl}>
          <TableCards
            cards={cards}
            createdAt={ticketGroup?.createdAt}
            updatedAt={ticketGroup?.updatedAt}
            filterValue={filter}
            setFilter={handleSetFilter}
            filterOptions={options}
            onUpload={openUploadEmails}
            onBulk={openBulkUpload}
            openPDFmodal={handlePdfModalOpen}
            openResetModal={openResetModal}
          />
          <GroupDetailTable
            tickets={filterTableData}
            listLoading={listLoading}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onSendTicket={onSendTicket}
            ticketState={ticketState}
            groupRefetch={groupRefetch}
            addGuestData={addGuestData}
            sendMultiple={sendMultiple}
            loadingSend={loadingSend}
            viewMetrics={viewMetrics}
            usedTickets={usedTickets}
            emailsMap={emailsMap}
            onUpdateGuest={onUpdateGuest}
            pageWidth={pageWidth}
            searchTickets={searchTickets}
            filter={filter}
            setFilter={setFilter}
            groupedTicketTypes={groupedTicketTypes}
            groupId={ticketGroup?.id}
          />
        </StyledTableContentWrapper>
      </PageWrapper>
      <BulkModal modalLvl={modalLvlBulk} setModalLvl={setModalLvlBulk} onSuccess={onBulk} />
      <PDFModal isVisible={pdfModal} onClose={handlePdfModalClose} groupId={ticketGroup?.id} />
    </>
  );
};
