import React from 'react';
import {RolesTable} from '../../components/Settings/ConfigurationsTable/Role';
import {useCreateRole, useDeleteRole, useGetPermissions, useGetRoles} from '../../hooks/settings';

export const RolesConfiguration = () => {
  const {tableData, refetch} = useGetRoles();
  const {create} = useCreateRole(refetch);
  const {onDelete} = useDeleteRole(refetch);

  const {permissions} = useGetPermissions();
  return <RolesTable onCreate={create} data={tableData} onDelete={onDelete} permissions={permissions} />;
};
