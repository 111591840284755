import React from 'react';
import {ManagementTable} from '../../components/Settings/ManagementTable';
import {useGetDepartments, useGetRoles, useGetUsers, useManageUserActions} from '../../hooks/settings';

export const Management = () => {
  const {options} = useGetDepartments();
  const {tableData, refetch, filterUsers} = useGetUsers();
  const {options: rolesOptions} = useGetRoles();
  const {onDelete} = useManageUserActions(refetch);

  return (
    <ManagementTable
      data={tableData}
      departments={options}
      roles={rolesOptions}
      onDelete={onDelete}
      refetch={refetch}
      filterUsers={filterUsers}
    />
  );
};
