const commonColors = {
  white: '#FFFFFF',
  black: '#000000',
  primaryBlack: '#262626',
  blue: '#1890FF',
  green: '#95DE64',
  yellow: '#FFFFB8',
  gold: '#FAAD14',
  red: '#F5222D',
  gray: '#D1D5DB',
  brown: '#937E6B',
  darkGray: '#495258',
  systemGray: '#CCCFD0',
  redBrown: '#964439',
  darkGreen: '#227B75',
  orange: '#CE931F',
  skyBlue: '#BECDCC',
  roseRed: '#CD796E',
  lightGreen: '#d1e3e1',
};

const backgroundColors = {
  white: '#FFFFFF',
  gray: '#F5F5F5',
  newGray: '#E5E5E5',
  neutral: '#FAFAFA',
  darkGray: '#D9D9D9',
  midnightBlue: '#041C2C',
  lightGold: '#F3EFEA',
  lightBrown: '#F4F2F0',
  black: '#020E16',
};

const borderColors = {
  lightGray: '#F0F0F0',
  darkGray: '#D9D9D9',
};

const Colors = {
  common: commonColors,
  background: backgroundColors,
  border: borderColors,
  text: {
    primary: '#262626',
    secondary: '#8C8C8C',
    tertiary: '#374151',
  },
};

export const palette = Colors;
