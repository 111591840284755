import styled from 'styled-components';
import {Table} from 'antd';
import {Media} from '../theme/breakpoints';
import {scrollStyles} from '../theme/scroll';

export const StyledPrimaryTable = styled(Table)`
  background: ${({theme}) => theme.palette.background.white};
  &.ant-table-wrapper {
    border-radius: 8px;
    margin-top: -14px;
    width: 100%;
    overflow-x: auto;
    ${scrollStyles};
    ${Media.down.l} {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
  & .ant-table table {
    //border-collapse: collapse;
    border-collapse: inherit;
    border-spacing: 0 12px;
  }
  & .ant-table-content {
    border-radius: 8px;
    background: ${({theme}) => theme.palette.background.white};
  }
  & .ant-table-thead th {
    background-color: ${({theme}) => theme.palette.background.lightGold};
    font-weight: ${({theme}) => theme.font.weight.regular};
    &::before {
      display: none;
    }
  }

  & .ant-table-tbody > tr > td {
    border-top: 1px solid ${({theme}) => theme.palette.border.lightGray};
    border-bottom: 1px solid ${({theme}) => theme.palette.border.lightGray};
  }

  & .ant-table-tbody tr {
    td {
      background-color: ${({theme}) => theme.palette.background.neutral};
    }
    td:first-of-type {
      border-left: 10px solid ${({theme}) => theme.palette.border.lightGray};
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      &::before {
        border-left: 9px solid #ffffff;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        content: '';
        height: 80px;
        position: absolute;
        top: -2px;
        left: -10px;
      }
    }
    td:last-of-type {
      border-right: 10px solid ${({theme}) => theme.palette.border.lightGray};
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      &::before {
        border-right: 9px solid #ffffff;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        content: '';
        position: absolute;
        height: 80px;
        top: -2px;
        right: -10px;
      }
    }
  }
`;

export const StyledTableWrapper = styled.div`
  background: ${({theme}) => theme.palette.background.white};
  /* border: 1px solid ${({theme}) => theme.palette.border.lightGray}; */
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 40px;
`;

export const StyledTableContentWrapper = styled.div`
  background: ${({theme}) => theme.palette.background.white};
  border: 1px solid ${({theme}) => theme.palette.border.lightGray};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px 20px;
`;
