import React from 'react';
import {DraggerProps} from 'antd/es/upload';
import {
  UploadIcon,
  UploadIconRow,
  UploadTitle,
  UploadDescr,
  StyledDragger,
  ResultWrapper,
  StyledImageDragger,
  UploadImageContent,
} from './styles';
import {Result} from 'antd';
import {Loader} from '../Loader';
import {ExclamationCircleFilled, FileImageOutlined} from '@ant-design/icons';

export type MessageResType = {title?: string; descr?: string} | null;

export type FileUploadProps = DraggerProps & {
  title?: string;
  descr?: string;
  success?: MessageResType;
  error?: MessageResType;
  onResultClick?: () => void;
  loading?: boolean;
  woTheme?: boolean;
  placeholder?: string;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  title,
  descr,
  success,
  error,
  onResultClick,
  loading,
  woTheme,
  ...props
}) => {
  const baseTitle = title || 'Click or drag file to this area to upload.';
  const baseDescr =
    descr ||
    'Add a properly formated promo codes file here for the group. You can upload a CSV file with the list of pomo codes only.';
  if (loading)
    return (
      <ResultWrapper $woTheme={woTheme}>
        <Loader />
      </ResultWrapper>
    );
  if (success)
    return (
      <ResultWrapper onClick={onResultClick} $woTheme={woTheme}>
        <Result status="success" title={success?.title} subTitle={success?.descr} />
      </ResultWrapper>
    );
  if (error)
    return (
      <ResultWrapper onClick={onResultClick} $woTheme={woTheme}>
        <Result status="error" title={error?.title} subTitle={error?.descr} icon={<ExclamationCircleFilled />} />
      </ResultWrapper>
    );
  return (
    <StyledDragger {...props} $woTheme={woTheme}>
      <UploadIconRow>
        <UploadIcon $woTheme={woTheme} />
      </UploadIconRow>
      <UploadTitle $woTheme={woTheme}>{baseTitle}</UploadTitle>
      <UploadDescr $woTheme={woTheme}>{baseDescr}</UploadDescr>
    </StyledDragger>
  );
};

export const ImageUpload: React.FC<FileUploadProps> = ({loading, woTheme, placeholder, ...props}) => {
  if (loading)
    return (
      <ResultWrapper $woTheme={woTheme}>
        <Loader />
      </ResultWrapper>
    );
  return (
    <StyledImageDragger {...props} $woTheme={woTheme}>
      <UploadImageContent>
        <div>{placeholder}</div>
        <FileImageOutlined />
      </UploadImageContent>
    </StyledImageDragger>
  );
};
