import React, {useEffect, useState} from 'react';
import {Modal, notification} from 'antd';
import {ModalDescr} from '../../components/Dashboard/styles';
import {LinkOutlined, WarningOutlined} from '@ant-design/icons';
import {ModalLvl} from '../../components/TicketGroup/View/Table';
import {
  CopyButtonWrapper,
  CopyButtons,
  ModalButtons,
  ModalPdf,
  ModalSubtitle,
  ModalTitle,
  ModalWrapper,
} from './styles';
import {Button, ButtonTypes} from '../../ui-kit/Button';
import {generatePdf, getPdfDownloadUrl} from '../../queries/ticketGroup';
// import {Loader} from '../../ui-kit/Loader';

type ShareModalProps = {
  isShare?: boolean;
  clientName?: string;
  clientEmail?: string;
  clientEmailList?: string[];
  modalLvl: ModalLvl;
  setModalLvl: (lvl: ModalLvl) => void;
  onSuccess?: () => Promise<{res: boolean; error: string} | void>;
};
const getTitleConfirm = (name?: string, email?: string, isShare?: boolean) =>
  !isShare
    ? `Are you ready to send a magic link to the ${name} at ${email}?`
    : `You are request to reshare access with ${name} at ${email}?`;
const getDescriptionConfirm = (isShare?: boolean) =>
  !isShare
    ? 'Please make sure all the tickets are uploaded and ready to be sent. With this action, the client will be provided access to their Client Portal and allowed to distribute tickets at their discretion.'
    : 'Upon confirming, the client will receive their Magic Link with access to this ticket group in their inbox.';
const getTitleSuccess = (isShare?: boolean) =>
  !isShare ? 'The access is shared successfully!' : 'The access is reshared successfully!';
const getDescriptionSuccess = (isShare?: boolean) =>
  !isShare
    ? 'The client will receive their Magic Link momentarily. They will have access to their ticket group(s) and start distributing tickets.'
    : 'The client will receive their Magic Link momentarily. They will have access to their group tickets portal and distribute tickets at their own discretion.';

export const ShareModal: React.FC<ShareModalProps> = ({
  isShare,
  clientEmail,
  clientEmailList,
  clientName,
  modalLvl,
  setModalLvl,
  onSuccess,
}) => {
  const [error, setError] = useState<string | undefined>('');
  const emails = clientEmailList?.length ? clientEmailList.join(', ') : clientEmail;

  useEffect(() => {
    if (modalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: getTitleConfirm(clientName, emails, isShare),
        content: <ModalDescr>{getDescriptionConfirm(isShare)}</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: async () => {
          const res = await onSuccess?.();
          if (res?.res) return setModalLvl(ModalLvl.success);
          setModalLvl(ModalLvl.error);
          setError(res?.error);
        },
        onCancel: () => {
          setModalLvl(ModalLvl.closed);
          setError('');
        },
      });
    }
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        title: getTitleSuccess(isShare),
        content: <ModalDescr>{getDescriptionSuccess(isShare)}</ModalDescr>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
          setError('');
        },
      });
    }
    if (modalLvl === ModalLvl.error) {
      Modal.error({
        title: `Error!`,
        content: <ModalDescr>{error}</ModalDescr>,
        okText: 'Close',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
          setError('');
        },
      });
    }
  }, [modalLvl]);

  return <></>;
};

export type BulkModalProps = {
  modalLvl: ModalLvl;
  setModalLvl: (lvl: ModalLvl) => void;
  onSuccess?: () => void;
};

export const BulkModal: React.FC<BulkModalProps> = ({modalLvl, setModalLvl, onSuccess}) => {
  useEffect(() => {
    if (modalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you ready to send tickets to all your guests?`,
        content: (
          <div>
            Please make sure the email addresses are correct. With this action, your guests will receive Enchant
            tickets. This action cannot be undone.
          </div>
        ),
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          onSuccess?.();
          setModalLvl(ModalLvl.success);
        },
        onCancel: () => setModalLvl(ModalLvl.closed),
      });
    }
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        title: `The tickets have been sent successfully!`,
        content: <div>Your guests will receive emails with Enchant tickets momentarily!</div>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
      });
    }
  }, [modalLvl]);

  return <></>;
};

export type PDFModalProps = {
  isVisible: boolean;
  onClose: () => void;
  groupId?: string | number;
};

export const PDFModal: React.FC<PDFModalProps> = ({isVisible, groupId: _groupId, onClose}) => {
  const [files, setFiles] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const groupId = String(_groupId);

  const onGenerate = async () => {
    if (!groupId || !_groupId) return;
    try {
      setLoading(true);
      const res = await generatePdf({groupId});
      if (res) {
        notification.success({
          message: 'PDF Generation',
          description: 'PDF was successfully generated!',
        });
      }
      console.log('res:', res);
      return true;
    } catch (error) {
      console.log('generate error:', error);
    } finally {
      setLoading(false);
    }
  };
  const onSingleDownload = async () => {
    if (!groupId || !_groupId) return;
    try {
      setLoading(true);
      const fileResp = await getPdfDownloadUrl({groupId, individual: false});
      const fileUrl = fileResp?.body.fileUrl;
      const key = 'single';
      const fileName = fileResp?.body.filename || 'tickets.pdf';
      setFiles((prev) => ({
        ...(prev || {}),
        [key]: {name: fileName, url: fileUrl},
        groupId,
      }));
      return true;
    } catch (error) {
      console.log('download error:', error);
    } finally {
      setLoading(false);
    }
  };

  const onIndividualDownload = async () => {
    if (!groupId || !_groupId) return;
    try {
      setLoading(true);
      const fileResp = await getPdfDownloadUrl({groupId, individual: true});
      const fileUrl = fileResp?.body.fileUrl;
      const key = 'individual';
      const fileName = fileResp?.body.filename || 'individual_pdfs.zip';
      setFiles((prev) => ({
        ...(prev || {}),
        [key]: {name: fileName, url: fileUrl},
        groupId,
      }));
      return true;
    } catch (error) {
      console.log('download error:', error);
    } finally {
      setLoading(false);
    }
  };

  const url_individual = files?.individual?.url;
  const name_individual = files?.individual?.name;
  const url_single = files?.single?.url;
  const name_single = files?.single?.name;

  useEffect(() => {
    onSingleDownload();
    onIndividualDownload();
  }, [groupId]);
  return (
    <ModalPdf visible={isVisible} onCancel={onClose} centered>
      <ModalWrapper>
        <ModalTitle>what PDF you would like to download?</ModalTitle>
        <ModalSubtitle>
          <b>Single PDF:</b> All tickets will be issued in one PDF file. This can be printed as a whole document with
          tickets on each page.
          <br />
          <b>Individual PDfs:</b> Each ticket will be issued as a separate PDF file, and downloaded as a zip file
          containing all PDFs. These can be printed or sent individually to guests.
        </ModalSubtitle>
        <ModalButtons>
          <Button
            variant={ButtonTypes.tertiary}
            // disabled={!url_single}
            loading={loading}
            onClick={onGenerate}>
            Generate PDFs
          </Button>
          {/* {loading && <Loader />} */}
          <Button
            variant={ButtonTypes.primary}
            href={url_single}
            download={name_single}
            // disabled={!url_single}
            loading={loading || !url_single}
            onClick={onClose}>
            Single PDF
          </Button>
          <Button
            variant={ButtonTypes.primary}
            href={url_individual}
            download={name_individual}
            // disabled={!url_individual}
            loading={loading || !url_individual}
            onClick={onClose}>
            Individual PDFs
          </Button>
        </ModalButtons>
        <CopyButtons>
          <CopyButton title={'Single PDF Link'} url={url_single} />
          <CopyButton title={'Zip File Link'} url={url_individual} />
        </CopyButtons>
      </ModalWrapper>
    </ModalPdf>
  );
};

const CopyButton: React.FC<{title?: string; url?: string}> = ({title, url}) => {
  if (!url) return null;
  const onClick = () => {
    navigator.clipboard.writeText(url);
    notification['success']({
      message: `${title} Copied!`,
      placement: 'bottomRight',
    });
  };
  return (
    <CopyButtonWrapper onClick={onClick}>
      <LinkOutlined />
      <span>Copy {title}</span>
    </CopyButtonWrapper>
  );
};
