import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import React from 'react';
import {useCheckValidation} from '../../../hooks/tickets';
import {ValidationButton, ValidationTag} from './styles';
import {Loader} from '../../../ui-kit/Loader';

type CheckValidationProps = {
  ticketUrl: string;
  isClicked: boolean;
  setIsClicked: (v: boolean) => void;
};

export const CheckValidation: React.FC<CheckValidationProps> = ({ticketUrl, isClicked, setIsClicked}) => {
  const {onCheck, isLoading, isValid} = useCheckValidation(ticketUrl, setIsClicked);

  return !isClicked ? (
    ticketUrl.length ? (
      <ValidationButton size="small" type="primary" onClick={onCheck}>
        Check
      </ValidationButton>
    ) : (
      <ValidationButton size="small" type="primary" disabled>
        Check
      </ValidationButton>
    )
  ) : isLoading ? (
    <ValidationTag $valid={isValid} icon={isValid ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>
      {isValid ? 'Valid' : 'Invalid'}
    </ValidationTag>
  ) : null;
};

export type checkValidationStatus = 'checked' | 'invalid' | 'none';
type CheckValidationLabelProps = {
  onClick: () => void;
  status: checkValidationStatus;
  isLoading?: boolean;
  canCheck: boolean;
};

export const CheckValidationLabel: React.FC<CheckValidationLabelProps> = ({status, onClick, isLoading, canCheck}) => {
  if (isLoading) return <Loader />;
  if (status === 'checked')
    return (
      <ValidationTag $valid={true} icon={<CheckCircleOutlined />}>
        Valid
      </ValidationTag>
    );
  if (status === 'invalid')
    return (
      <ValidationTag $valid={false} icon={<CloseCircleOutlined />}>
        Invalid
      </ValidationTag>
    );

  return (
    <ValidationButton size="small" type="primary" onClick={onClick} disabled={!canCheck}>
      Check
    </ValidationButton>
  );
};
