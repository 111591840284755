import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {Media} from '../theme/breakpoints';
import {KIT_THEME} from '../theme/theme';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({theme}) => theme.palette.background.newGray};
`;

export const MainWrapper = styled.main.attrs({className: 'page-content'})<{$changeMargin: boolean}>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  margin-bottom: ${({$changeMargin}) => ($changeMargin ? '0' : '95px')};

  ${Media.down.m} {
    margin-bottom: 0px;
    background-color: ${({theme}) => theme.palette.background.white};
  }
`;

export type WithChildren = {
  children?: React.ReactNode;
};

type AppLayoutProps = WithChildren & {
  hideHeader?: boolean;
  hideFooter?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  noFooterOffset?: boolean;
};

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  header,
  footer,
  hideHeader,
  hideFooter,
  noFooterOffset,
  ...props
}) => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('body')?.classList.add(`theme-${KIT_THEME}`);
  }, [pathname]);

  return (
    <StyledLayout {...props}>
      {!hideHeader && header}
      <MainWrapper $changeMargin={!!hideFooter || !!noFooterOffset}>{children}</MainWrapper>
      {!hideFooter && footer}
    </StyledLayout>
  );
};
