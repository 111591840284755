import {atom} from 'recoil';

export type ModalsStateType = {
  subsequent?: boolean;
  helpExpand?: boolean;
  onboarding: boolean;
  triggerUpload?: boolean;
  triggerOnboarding?: 'start' | 'end' | string;
};
export const modals = atom<ModalsStateType>({
  key: 'modals',
  default: {helpExpand: undefined, onboarding: false, triggerUpload: false, subsequent: false},
});
