import {GroupDetailTableDataT, TicketStatusTypes} from '../types/tickets';

export const getFilterTableData = (type: TicketStatusTypes, tableData?: GroupDetailTableDataT[]) => {
  switch (type) {
    case TicketStatusTypes.all:
      return tableData;
      break;

    case TicketStatusTypes.sent:
      return tableData?.filter((el, idx) => idx === 0 || el.actions?.ticketStatus === TicketStatusTypes.sent);
      break;

    case TicketStatusTypes.pending:
      return tableData?.filter((el, idx) => idx === 0 || el.actions?.ticketStatus === TicketStatusTypes.notsent);

      break;

    default:
      return tableData;
  }
};

export const getErrorResponse = (e: unknown) => {
  try {
    const jsonErr = JSON.parse(JSON.stringify(e));
    return jsonErr?.response?.text && JSON.parse(jsonErr?.response?.text);
  } catch (e) {
    return false;
  }
};
