import React, {useEffect, useState} from 'react';
import {RecoilRoot} from 'recoil';
import {WithChildren} from '../types/helpers';
import {getToken, useCheckAuthorize} from '../hooks/auth';
import {session} from '../states/session';
import {User as UserType} from '../types/auth';

const RecoilProvider: React.FC<WithChildren> = ({children}) => {
  const [userState, setUserState] = useState<UserType | null>(null);
  const checkAuth = useCheckAuthorize();

  const token = getToken();
  useEffect(() => {
    const getUser = async () => {
      const res = await checkAuth();
      if (res) setUserState(res);
    };
    getUser();
  }, []);
  return (
    <RecoilRoot
      initializeState={({set}) => {
        if (token && userState) return set(session, {sessionToken: token, user: userState});
        set(session, null);
      }}>
      {children}
    </RecoilRoot>
  );
};

export default RecoilProvider;
