import React from 'react';
import {MobileWrapper, WarningContentWrapper, LogoWrapper, StyledLogo, WarningTitle, WarningDescr} from './styles';
import logo from '../assets/ENC_Logo.png';

export const MobileWarning = () => {
  return (
    <MobileWrapper>
      <WarningContentWrapper>
        <LogoWrapper>
          <StyledLogo src={logo} />
        </LogoWrapper>
        <WarningTitle>Check out our website on a desktop device.</WarningTitle>
        <WarningDescr>
          We don’t currently support mobile access for Enchant Portals, please visit this on your desktop device
        </WarningDescr>
      </WarningContentWrapper>
    </MobileWrapper>
  );
};
