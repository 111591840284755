import {validateEmail} from './tickets';

export const removeEmptyKeys = <T>(obj: any): T => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (!!obj?.[key]) {
      // eslint-disable-next-line
            // @ts-ignore
      result[key] = obj[key];
    }
  });
  return result as T;
};

export const parseError = (e: unknown) => {
  const error = JSON.parse(JSON.stringify(e));
  const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
  return errorMessage || '';
};

export const MAX_TICKETS = 15;

export const parseStringArray = (value?: string, separator?: string) =>
  value
    ?.replaceAll(' ', '')
    .split(separator || ',')
    ?.filter((el) => !!el);

export const validateEmailsArray = (arr?: string[]) => !arr?.some((el) => !validateEmail(el));
