import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {Typography} from 'antd';
import {TicketAccessTypes} from '../../types/tickets';
import {defaultTheme} from '../../ui-kit/theme/theme';
import {Loader} from '../../ui-kit/Loader';

const {Text} = Typography;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 190px 32px 190px;
  ${Media.down.xxl} {
    padding: 54px 90px 32px 90px;
  }
`;

export const TitleLine = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatusBlock = styled.div`
  display: flex;
  align-items: center;
`;

const getStatusColor = (status?: TicketAccessTypes) => {
  if (status === TicketAccessTypes.shared) return defaultTheme.palette.common.green;
  return defaultTheme.palette.common.primaryBlack;
};

export const StatusText = styled(Text)<{status?: TicketAccessTypes}>`
  & > span {
    color: ${({status}) => getStatusColor(status)};
    text-transform: capitalize;
  }
  padding-right: 15px;
`;

export const CheckingLoader = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  width: 100%;
  height: 100%;
`;

export const WhiteLoader = styled(Loader)``;
