import React from 'react';
import {StyledLinkBack, ArrowBackIcon, LinkBackText} from './styles';
import {WithChildren} from '../AppLayout';

export const LinkBack: React.FC<WithChildren & {to?: string; onClick?: (e: any) => void}> = ({
  children,
  onClick,
  to,
  ...props
}) => {
  return (
    <StyledLinkBack to={to || '#'} onClick={onClick} {...props}>
      <ArrowBackIcon />
      <LinkBackText>{children}</LinkBackText>
    </StyledLinkBack>
  );
};
