import styled from 'styled-components';
import mobile_background from '../assets/mobile_background.png';
import {Typography} from 'antd';
const {Text} = Typography;

export const MobileWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-image: url(${mobile_background}), linear-gradient(0.04deg, #041c2c 10.75%, rgba(4, 28, 44, 0.9) 57.44%);
  background-repeat: no-repeat;
  background-size: cover;
`;

export const WarningContentWrapper = styled.div`
  width: 100%;
  margin-top: 30%;
  display: flex;
  flex-direction: column;
`;
export const LogoWrapper = styled.div`
  width: 100%;
`;

export const StyledLogo = styled.img`
  width: 70%;
  margin: 0 auto;
  display: flex;
`;

export const WarningTitle = styled(Text)`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding: 0 20px;
  margin-top: 10px;
  background: linear-gradient(97.25deg, #a58569 1.59%, #c2a06a 25.64%, #dfc999 50.98%, #c2a06a 75.24%, #a58569 97.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const WarningDescr = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 0 20px;
  margin-top: 10px;
`;
