import {useViewer} from '../hooks/auth';
import {PortalsList} from '../types/auth';
import {UserT} from '../queries/types/user';

export type permissionsList = {
  haveSettings?: boolean;
  isSuperAdmin?: boolean;
  promoAccess?: boolean;
  isDeptManager?: boolean;
  ticketAccess?: boolean;
  notificationsAccess?: boolean;
};

export const getPermissions = () => {
  const user = useViewer();
  return {
    haveSettings: !!user?.roles?.find((el) => el === 'SUPER ADMIN'),
    isSuperAdmin: !!user?.roles?.find((el) => el === 'SUPER ADMIN'),
    isAssociate: !!user?.roles?.find((el) => el === 'ASSOCIATE'),
    isDeptManager: !!user?.roles?.find((el) => el === 'DEPT MANAGER'),
    promoAccess: !!user?.portals?.find((el) => el?.toLowerCase() === PortalsList.promo.toLowerCase()),
    ticketAccess: !!user?.portals?.find((el) => el?.toLowerCase() === PortalsList.ticket.toLowerCase()),
    notificationsAccess: !!user?.portals?.find((el) => el?.toLowerCase() === PortalsList.notifications.toLowerCase()),
  };
};

export const filterAssociatesByDepartment = (portals?: string[], departments?: string[], associates?: UserT[]) => {
  return associates?.filter(
    (el) =>
      el?.departments?.some((dep) => departments?.includes(dep)) && el?.portals?.some((dep) => portals?.includes(dep)),
  );
};
