import styled from 'styled-components';
import {Form, Tag, Typography, Select} from 'antd';
import {FormItem} from '../../../ui-kit/Input';
import {Button} from '../../../ui-kit/Button';
const {Text} = Typography;

export const CodeForm = styled(Form)`
  padding: 18px 27px 21px 27px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.background.darkGray};
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.background.white};
  max-width: 510px;
`;

export const GroupTitle = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.bold};
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin-bottom: 22px;
`;

export const CodeFormItem = styled(FormItem)`
  && {
    margin-bottom: 22px;
  }
  .ant-form-item-explain-error {
    color: ${({theme}) => theme.palette.common.redBrown};
    font-size: 10px;
    margin-top: 8px;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
  }
  .ant-form-item-required {
    width: 100%;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    cursor: pointer;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 3px;
  }
`;

export const StyledSpan = styled.span`
  font-size: 12px;
`;

export const FormButton = styled(Button)`
  width: 100%;
`;

export const TooltipWrapper = styled.div`
  margin-left: 5px;
`;

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({theme}) => theme.palette.common.brown};
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  .ant-select-arrow {
    color: ${({theme}) => theme.palette.common.darkGray};
  }
`;

export const ValidationButton = styled(Button)`
  border-radius: 4px;
  &.ant-btn-primary[disabled] {
    color: ${({theme}) => theme.palette.common.white};
    background-color: ${({theme}) => theme.palette.common.brown};
    opacity: 0.5;
  }
`;

export const ValidationTag = styled(Tag)<{$valid?: boolean}>`
  background-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  border-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  color: ${({theme}) => theme.palette.common.white};
  border-radius: 4px;
  height: 25px;
  width: 70px;
  padding-top: 2px;
  padding-left: 10px;
`;

export const WithHint = styled.div`
  position: relative;
`;

export const SelectButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectOptionsButton = styled(Button)`
  margin-right: 16px;
  margin-bottom: 4px;
`;

export const SuccessRow = styled.div`
  color: ${({theme}) => theme.palette.common.darkGreen};
  text-align: center;
`;

export const ErrorLine = styled.div`
  padding: 10px 0px;
  color: ${({theme}) => theme.palette.common.red};
`;

export const ErrorText = styled(Text)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.red};
`;

export const SuccessLine = styled.div`
  padding: 10px 0px;
  text-align: center;
`;

export const SuccessText = styled(Text)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.darkGreen};
`;

export const LinkError = styled.div`
  height: 22px;
  padding-top: 4px;
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.redBrown};
`;

export const PreviewBlock = styled.div`
  display: flex;
  width: 100%;
  background-color: #000;
  margin-bottom: 20px;
  margin-top: -10px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    color: #fff;
    line-height: 16px;
    font-size: 12px;
  }

  span {
    width: 30px;
    height: 20px;
    display: flex;
    margin-right: 10px;
    color: #cccfd0;
    font-size: 20px;
  }
`;

export const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;
