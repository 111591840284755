import React from 'react';
import {
  CardDateInfoLabel,
  CardInfoRow,
  CardInfoValue,
  CardsRow,
  TableCardsOptionsWrapper,
  TableCardsWrapper,
  TableTitleCard,
  UploadButtonWrapper,
} from './styles';
import {format} from 'date-fns';
import {StaticCard} from '../../../ui-kit/StaticCard';
import {Button, Dropdown, MenuProps, Select} from 'antd';
import {TicketsCardT, TicketStatusTypes} from '../../../types/tickets';
import {SelectOptionT} from '../../../types/helpers';

type TableCardsProps = {
  cards?: TicketsCardT[];
  createdAt?: string | number;
  updatedAt?: string | number;
  filterValue?: TicketStatusTypes;
  setFilter?: (v: TicketStatusTypes) => void;
  filterOptions?: SelectOptionT[];
  upper?: boolean;
  onUpload?: () => void;
  onBulk?: () => void;
  openPDFmodal?: () => void;
  openResetModal?: () => void;
};

export const TableCards: React.FC<TableCardsProps> = ({
  cards,
  createdAt,
  updatedAt,
  filterValue,
  setFilter,
  filterOptions,
  onUpload,
  openPDFmodal,
  onBulk,
  openResetModal,
}) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div onClick={openPDFmodal}>Download PDF</div>,
    },
    {
      key: '2',
      label: <div onClick={onUpload}>Upload Emails</div>,
    },
    ...(onBulk
      ? [
          {
            key: '3',
            label: <div onClick={onBulk}>Bulk send tickets</div>,
          },
        ]
      : []),
    {key: '4', label: <div onClick={openResetModal}>Reset Group Portal</div>},
  ];

  return (
    <TableCardsWrapper>
      <CardsRow>
        <TableTitleCard>
          <CardInfoRow>
            <CardDateInfoLabel>Created</CardDateInfoLabel>
            <CardInfoValue>{createdAt && format(new Date(createdAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
          <CardInfoRow>
            <CardDateInfoLabel>Updated</CardDateInfoLabel>
            <CardInfoValue>{updatedAt && format(new Date(updatedAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
        </TableTitleCard>
        {cards?.map((el) => (
          <StaticCard key={el.label} {...el} />
        ))}
      </CardsRow>
      <TableCardsOptionsWrapper>
        <UploadButtonWrapper>
          {/* <UploadButton type={'primary'} variant={ButtonTypes.secondary} onClick={openPDFmodal}>
            Download PDF
          </UploadButton>
          <UploadButton type={'primary'} variant={ButtonTypes.secondary} onClick={onUpload}>
            Upload Emails
          </UploadButton>
          {onBulk && (
            <UploadButton type={'primary'} variant={ButtonTypes.secondary} onClick={onBulk}>
              Bulk send tickets
            </UploadButton>
          )} */}
          <Dropdown menu={{items}} placement="bottomLeft" arrow>
            <Button>Actions</Button>
          </Dropdown>
        </UploadButtonWrapper>
        <Select options={filterOptions} value={filterValue} onChange={setFilter} />
      </TableCardsOptionsWrapper>
    </TableCardsWrapper>
  );
};
