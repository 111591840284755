import React from 'react';
import {WarningOutlined} from '@ant-design/icons';
import {Modal} from 'antd';

export const ResetModal = (onReset?: () => Promise<void>) => {
  const openResetModal = () =>
    Modal.confirm({
      title: 'Are you sure you want to reset this group portal?',
      content:
        'This will remove all uploaded contacts and ticket assignments. The client will need to add their contacts again and resend their emails.',
      okText: 'Yes',
      cancelText: 'No',
      autoFocusButton: null,
      icon: <WarningOutlined />,
      onOk: onReset,
    });

  return {openResetModal};
};
