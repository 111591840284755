import styled, {css} from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';
import {PrimaryTable, TableWrapper} from '../../ui-kit/Table';
import {Button} from '../../ui-kit/Button';
import Input from '../../ui-kit/Input';
import {StyledSelect} from '../TicketGroup/Form/styles';
import {Typography} from 'antd';
import {SelectWithCheckbox} from '../../ui-kit/Select';
const {Title} = Typography;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 80px 32px 80px;
  ${Media.down.xxl} {
    padding: 44px 35px 32px 35px;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftPart = styled.div`
  margin-right: 32px;
`;

export const SwitchCard = styled.div`
  background-color: ${({theme}) => theme.palette.background.white};
  border-radius: 4px;
  padding: 18px 0;
  box-sizing: border-box;
  height: 270px;
  width: 190px;
`;

export const ConfigCard = styled.div<{$hidden?: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'block')};
  border-radius: 4px;
  padding: 0 40px;
  box-sizing: border-box;
`;

export const SwitchOption = styled.div<{$active?: boolean}>`
  font-size: 16px;
  line-height: 24px;
  padding-left: 20px;
  cursor: pointer;
  transition: 0.15s all;
  color: ${({$active, theme}) => ($active ? theme.palette.text.primary : theme.palette.text.secondary)};
  font-weight: ${({$active, theme}) => ($active ? theme.font.weight.bold : theme.font.weight.regular)};
  border-left: ${({$active, theme}) =>
    $active ? `4px solid ${theme.palette.common.brown}` : `4px solid ${theme.palette.common.white}`};
  background-color: ${({$active, theme}) => ($active ? theme.palette.background.lightBrown : 'none')};
  margin-bottom: 9px;
`;

export const ConfigOption = styled.div<{$active?: boolean}>`
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s all;
  color: ${({$active, theme}) => ($active ? theme.palette.text.primary : theme.palette.text.secondary)};
  font-weight: ${({$active, theme}) => ($active ? theme.font.weight.bold : theme.font.weight.regular)};
  margin-bottom: 9px;
`;

export const TablePart = styled(TableWrapper)`
  width: 100%;
  border: none;
  padding: 0;
  background-color: transparent;
`;

export const Table = styled(PrimaryTable)`
  background-color: transparent;
  & .ant-table {
    background-color: transparent;
  }
  & .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: end;
    padding-right: 24px;
  }
`;

export const StyledSpan = styled.span`
  margin-right: 8px;
`;

export const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const buttonStyle = css`
  border: none;
  background-color: transparent;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  padding-right: 0;
  :hover {
    background-color: transparent;
  }
`;

export const ActionBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  color: ${({theme}) => theme.palette.common.brown};
`;

export const RemoveBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  color: ${({theme}) => theme.palette.common.redBrown};
`;

export const ItemWrapper = styled.div``;

export const FullNameInput = styled(Input)`
  min-width: 150px;
`;

export const TypeInput = styled(Input)`
  width: 150px;
`;

export const EmailInput = styled(Input)`
  min-width: 225px;
`;

export const ConfigurationDepartmentWrapper = styled(ItemWrapper)`
  text-transform: capitalize;
`;

export const ConfigurationDepartmentInput = styled(Input)`
  width: 142px;
`;

export const RoleSelect = styled(StyledSelect)`
  min-width: 132px;
  :hover {
    border-color: green;
  }
`;

// export const DepartmentSelect = RoleSelect;
// export const AccessSelect = RoleSelect;

export const DepartmentSelect = styled(StyledSelect)`
  width: 125px;
  :hover {
    border-color: green;
  }
`;

export const AccessSelect = styled(SelectWithCheckbox)`
  min-width: 160px;
  :hover {
    border-color: green;
  }
`;

export const SettingsLoaderWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

export const TableTitle = styled(Title).attrs({level: 3})`
  margin-bottom: 10px;
`;

export const UsersFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 14px;
`;

export const FilterSelect = styled(StyledSelect)`
  width: 170px;
`;

export const ClearButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
`;
