import {Route} from '../helpers/Route';

export enum SearchKey {
  type = 'type',
  from = '',
  to = '',
}

export const route = {
  //redirect route
  redirectToLogin: Route.of({path: '/redirect-to-login-page'}),

  //private routes
  dashboard: Route.of({path: '/'}),
  createTicketGroup: Route.of({path: '/create-ticket-group'}),
  editTicketGroup: Route.of<{id: string}>({path: '/edit-ticket-group/:id'}),
  viewTicketGroup: Route.of<{id: string}>({path: '/view-ticket-group/:id'}),
  settings: Route.of({path: '/settings'}),
};

export const routePaths = {
  viewTicketGroup: 'view-ticket-group',
  editTicketGroup: 'edit-ticket-group',
  createTicketGroup: 'create-ticket-group',
};
