import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Form} from '../../components/TicketGroup/Form/Form';
import {useGetAssociates, useViewer} from '../../hooks/auth';
import {useCreateTickets} from '../../hooks/tickets';
import {getPermissions} from '../../helpers/roles';

export const CreateTicket = () => {
  const createData = useCreateTickets();
  const navigate = useNavigate();
  const {associatesOptions} = useGetAssociates();
  const user = useViewer();
  const {isAssociate, isDeptManager} = getPermissions();

  return (
    <Form
      mode={'create'}
      formData={createData}
      navigate={navigate}
      associatesOptions={associatesOptions}
      user={user}
      isAssociate={isAssociate}
      isDeptManager={isDeptManager}
    />
  );
};
