import {SettingsQueries} from '../constants/api';
import {deleteQuery, getQuery, postQuery, putQuery} from './hooks';
import {
  createPermissionReq,
  updatePermissionReq,
  deletePermissionReq,
  createRoleReq,
  updateRoleReq,
  deleteRoleReq,
  createDepartmentReq,
  updateDepartmentReq,
  deleteDepartmentReq,
  createUserReq,
  updateUserReq,
} from './types/settings';

export const getListPermissions = async () => await getQuery(SettingsQueries.getPermissions());
export const createPermission = async ({body}: createPermissionReq) =>
  await postQuery(SettingsQueries.createPermission()).send(body);
export const updatePermission = async (params: updatePermissionReq) =>
  await putQuery(SettingsQueries.updatePermission(params.permissionId));
export const deletePermission = async (params: deletePermissionReq) =>
  await deleteQuery(SettingsQueries.deletePermission(params.permissionId));

export const getListRoles = async () => await getQuery(SettingsQueries.getRoles());
export const createRole = async ({body}: createRoleReq) => await postQuery(SettingsQueries.createRole()).send(body);
export const updateRole = async (params: updateRoleReq) => await putQuery(SettingsQueries.updateRole(params.roleId));
export const deleteRole = async (params: deleteRoleReq) => await deleteQuery(SettingsQueries.deleteRole(params.roleId));

export const getListDepartments = async () => await getQuery(SettingsQueries.getDepartments());
export const createDepartment = async ({body}: createDepartmentReq) =>
  await postQuery(SettingsQueries.createDepartment()).send(body);
export const updateDepartment = async (params: updateDepartmentReq) =>
  await putQuery(SettingsQueries.updateDepartment(params.departmentId));
export const deleteDepartment = async (params: deleteDepartmentReq) =>
  await deleteQuery(SettingsQueries.deleteDepartment(params.departmentId));

export const getAllUsers = async () => await getQuery(SettingsQueries.getUsers());
export const inviteUser = async ({body}: createUserReq) => await postQuery(SettingsQueries.getUsers()).send(body);
export const deleteUser = async (id: string) => await deleteQuery(SettingsQueries.deleteUser(id));
export const updateUser = async ({id, body}: updateUserReq) =>
  await putQuery(SettingsQueries.updateUser(id)).send(body);
