import React, {useEffect, useState} from 'react';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {ModalLvl} from '../../components/TicketGroup/View/Table';
import {routePaths} from '../../constants/routes';
import {useGetTicketGroup, useSendNotSentEmails, useShareTickets} from '../../hooks/tickets';
import {ShareModal, BulkModal} from '../TicketGroup/ShareModal';
import {CodeAccessTypes} from '../../ui-kit/helpers/codes';
import {openNotifError} from '../../components/Dashboard';

const StyledFooter = styled(Footer)<{$fixed?: boolean}>`
  ${({$fixed}) => $fixed && `position: fixed; bottom: 0; left: 0;`};
`;

type TicketFooterContainerT = {
  onSuccess?: () => void;
};

type InternalTicketFooterContainerT = {
  onSuccess?: () => void;
  onBulk?: () => Promise<void>;
};

export const TicketFooterContainer: React.FC<TicketFooterContainerT> = ({onSuccess}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [shareText, setShareText] = useState('');
  const {pathname} = useLocation();
  const isCodePage = pathname.includes(routePaths.viewTicketGroup);

  const id = isCodePage ? pathname.split('/')?.[2] : '';
  const {ticketGroup, refetch} = useGetTicketGroup(id, !isCodePage);
  const onShareSuccess = () => {
    refetch();
    onSuccess?.();
  };
  const {shareTicket} = useShareTickets(id, onShareSuccess);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => await shareTicket();

  useEffect(() => {
    setShareText(ticketGroup?.outboundStatus === CodeAccessTypes.sent ? 'Reshare Access' : 'Share Access');
  }, [ticketGroup?.outboundStatus]);
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);
  const isShare = ticketGroup?.outboundStatus === CodeAccessTypes.sent ? true : false;

  return (
    <>
      <ShareModal
        isShare={isShare}
        clientEmail={ticketGroup?.clientEmail}
        clientEmailList={ticketGroup?.clientEmailList}
        clientName={ticketGroup?.clientName}
        modalLvl={modalLvl}
        setModalLvl={changeModalLvl}
        onSuccess={onShare}
      />
      <StyledFooter showReshare={true} buttonText={shareText} $fixed={true} onReshare={onShareClick} />
    </>
  );
};

export const InternalTicketFooterContainer: React.FC<InternalTicketFooterContainerT> = ({onSuccess, onBulk}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [isShared, setIsShared] = useState(false);
  const {pathname} = useLocation();
  const isGroupPage = pathname.includes(routePaths.viewTicketGroup);
  const id = isGroupPage ? pathname.split('/')?.[2] : '';
  const {send} = useSendNotSentEmails([id], openNotifError);
  const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShare = async () => {
    await send();
    await onBulk?.();
    onSuccess?.();
    setIsShared(true);
    return {res: true, error: ''};
  };
  const showReshare = isGroupPage && !isShared;
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);

  return (
    <>
      <BulkModal modalLvl={modalLvl} setModalLvl={changeModalLvl} onSuccess={onShare} />
      <StyledFooter
        showReshare={showReshare}
        buttonText={'Bulk Send Tickets'}
        $fixed={showReshare}
        onReshare={onShareClick}
      />
    </>
  );
};

const FooterContainer = () => {
  return null;
};
export default FooterContainer;
