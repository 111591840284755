import React, {useState} from 'react';
import {
  LeftPart,
  PageWrapper,
  SwitchCard,
  SwitchContainer,
  SwitchOption,
  ConfigOption,
  TablePart,
  ConfigCard,
} from './styles';
import {Management} from '../../containers/Settings/Management';
import {Configurations} from '../../containers/Settings/Configurations';
import {ConfigTypeT} from '../../types/settings';

export const Settings = () => {
  const [tableType, setTableType] = useState<'conf' | 'manage'>('manage');
  const [configType, setConfigType] = useState<ConfigTypeT>('role');
  const [hidden, setHidden] = useState<boolean>(true);
  return (
    <PageWrapper>
      <SwitchContainer>
        <LeftPart>
          <SwitchCard>
            <SwitchOption
              onClick={() => {
                setTableType('manage');
                setHidden(true);
              }}
              $active={tableType === 'manage'}>
              User Management
            </SwitchOption>
            <SwitchOption
              onClick={() => {
                setTableType('conf');
                setHidden(false);
              }}
              $active={tableType === 'conf'}>
              Configurations
            </SwitchOption>
            <ConfigCard $hidden={hidden}>
              <ConfigOption onClick={() => setConfigType('role')} $active={configType === 'role'}>
                Role
              </ConfigOption>
              <ConfigOption onClick={() => setConfigType('department')} $active={configType === 'department'}>
                Department
              </ConfigOption>
              <ConfigOption onClick={() => setConfigType('ticket')} $active={configType === 'ticket'}>
                Ticket
              </ConfigOption>
              <ConfigOption onClick={() => setConfigType('permission')} $active={configType === 'permission'}>
                Permissions
              </ConfigOption>
            </ConfigCard>
          </SwitchCard>
        </LeftPart>
        <TablePart>{tableType === 'manage' ? <Management /> : <Configurations configType={configType} />}</TablePart>
      </SwitchContainer>
    </PageWrapper>
  );
};
