import React, {useEffect, useState} from 'react';
import {Table, TableTitle} from '../styles';
import {TableWrapper} from '../../../ui-kit/Table';
import {PermissionTableColumnsTitles, PermissionTableFields, TableColumnT} from '../../../helpers/table';
import {
  ActionsItem,
  ActionsItemProps,
  PermissionDescrItem,
  PermissionDescrProps,
  PermissionNameItem,
  PermissionNameProps,
} from './TableItems';
import {Modal} from 'antd';
import {ModalDescr} from '../../Dashboard/styles';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {PermissionTableItemT} from '../../../types/settings';
import {PermissionFormT} from '../../../types/formData';
import {updateDepValues} from '../../../hooks/settings';
import {CenteredLoader} from '../../../ui-kit/Loader';
import {CreatePermissionField} from '../../../queries/types/settings';
import {ModalLvl} from '../../TicketGroup/View/Table';

type DepartmentTablePropsT = {
  data: PermissionTableItemT[];
  onCreate: (v: PermissionFormT) => void;
  onDelete: (id?: string) => void;
  onUpdate?: (v: updateDepValues) => void;
  loading?: boolean;
};

export const PermissionsTable: React.FC<DepartmentTablePropsT> = ({data, onCreate, onDelete, loading}) => {
  const [addName, setAddName] = useState<string>('');
  const [addDescription, setAddDescription] = useState<string>('');

  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [isEditing, setIsEditing] = useState(false);
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [addModalLvl, setAddModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const setFocusId = (id?: string) => setFocusedId(id);
  const setEditing = (b: boolean) => setIsEditing(b);

  const handleChange = (type: 'descr' | 'name') =>
    type === 'name' ? (e: any) => setAddName(e?.target.value) : (e: any) => setAddDescription(e?.target.value);

  const onAdd = async () => {
    await onCreate({[CreatePermissionField.name]: addName, [CreatePermissionField.description]: addDescription});
    setAddModalLvl(ModalLvl.success);
    setAddDescription('');
    setAddName('');
  };
  const columns: TableColumnT[] = [
    {
      key: PermissionTableFields.name,
      dataIndex: PermissionTableFields.name,
      title: PermissionTableColumnsTitles.name,
      render: (props: PermissionNameProps) => {
        return <PermissionNameItem {...props} addName={addName} onChange={handleChange('name')} />;
      },
    },
    {
      key: PermissionTableFields.description,
      dataIndex: PermissionTableFields.description,
      title: PermissionTableColumnsTitles.description,
      render: (props: PermissionDescrProps) => {
        return <PermissionDescrItem {...props} addDescription={addDescription} onChange={handleChange('descr')} />;
      },
    },
    {
      key: PermissionTableFields.actions,
      dataIndex: PermissionTableFields.actions,
      title: PermissionTableColumnsTitles.action,
      render: (props: ActionsItemProps) => (
        <ActionsItem
          {...props}
          onAdd={onAdd}
          configType={'department'}
          setDelPopup={setDelPopup}
          setFocusId={setFocusId}
          setEditing={setEditing}
          focusedId={focusedId}
          isEditing={isEditing}
        />
      ),
    },
  ];
  useEffect(() => {
    const depName = data.find((el) => el.id?.id === focusedId)?.name?.name || 'department';
    if (delModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `You are request to remove ${depName}?`,
        content: <ModalDescr>Upon confirming, the permission will be removed from the system.</ModalDescr>,
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <QuestionCircleOutlined />,
        onOk: () => {
          onDelete?.(focusedId);
          setDelPopup(ModalLvl.success);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `${depName} is removed!`,
        content: <ModalDescr>The permission has been removed from the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    if (addModalLvl === ModalLvl.success) {
      Modal.success({
        title: `Success!`,
        content: <ModalDescr>The permission has been added to the system.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          setAddModalLvl(ModalLvl.closed);
        },
      });
    }
  }, [addModalLvl]);
  return (
    <>
      <TableTitle level={3}>Department</TableTitle>
      <TableWrapper>
        <Table columns={columns} dataSource={data} pagination={false} />
        {loading && <CenteredLoader />}
      </TableWrapper>
    </>
  );
};
